<template>
  <div>
    <Header
      :title="$t('mapping.configuration').toString()"
      :subtitle="subtitle"
      :items="headerButtons"
      :show-back-button="true"
      @back="$router.push({ name: 'projectMappingsMappings' })"
      @save="saveMapping"
      @showVersions="onShowVersions"
    />
    <div class="card card-custom">
      <div class="card-body">
        <FormHelper
          ref="form"
          v-model="mapping"
          :form="form"
          :config="config"
        />
      </div>
    </div>

    <BackupModal
      v-model="showVersionsModal"
      :object="mapping"
      classname="Mapping"
    />
  </div>
</template>

<script>
import DataStructures from "@/components/DataStructures/dataStructures";
import Mapping from "@/components/Mappings/mapping";
import { mapActions, mapGetters } from "vuex";
import Header from "@/components/Tools/Header/Header.vue";
import FormHelper from "@/components/Tools/FormHelper/FormHelper.vue";
import {
  addEventToLoadingQueue,
  removeEventFromLoadingQueue
} from "@/composables/useLoadingQueue";
import { formatDate } from "@/components/Tools/helperFunctions";
import BackupModal from "@/components/Backup/BackupModal.vue";
import { REMOVE_MAPPING_FIELDS } from "@/core/services/store/mapping.module";
import { useWorkNote } from "@/composables/useWorkNote";

export default {
  components: { BackupModal, FormHelper, Header },
  props: {},
  data() {
    return {
      config: {
        snippetPrefix: "assignments",
        enableVariables: false
      },
      form: [
        {
          label: this.$t("mapping.mappingLabel"),
          name: "label",
          type: "text",
          disableReturnType: true,
          default: "",
          validations: {
            required: true,
            minLength: 3
          }
        },
        {
          label: this.$t("mapping.mappingDescription"),
          name: "description",
          type: "textarea",
          default: "",
          validations: {
            required: false
          }
        },
        {
          label: this.$t("mapping.projects"),
          name: "projects",
          type: "multiselect",
          clearable: true,
          options: [],
          validations: {
            required: true
          }
        },
        {
          label: this.$t("mapping.mappingDataStructureSource"),
          name: "source_data_structure_id",
          type: "select",
          options: this.dataStructures,
          validations: {
            required: true
          },
          disabled: true
        },
        {
          label: this.$t("mapping.mappingDataStructureTarget"),
          name: "target_data_structure_id",
          type: "select",
          options: [],
          validations: {
            required: true
          },
          disabled: true
        }
      ],
      mapping: {
        label: "",
        description: "",
        source_data_structure_id: undefined,
        target_data_structure_id: undefined,
        projects: []
      },
      showVersionsModal: false
    };
  },
  computed: {
    ...mapGetters([
      "projects",
      "isPresetProject",
      "selectedProject",
      "selectedPresetVersion",
      "isDevPresetVersion"
    ]),
    // Header subtitle
    subtitle: function () {
      if (!this.mapping) {
        return "";
      }

      let subtitleParts = [];
      if (this.mapping.updated_by_user?.full_name) {
        subtitleParts.push(this.mapping.updated_by_user?.full_name);
      }
      if (this.mapping.updated_at) {
        subtitleParts.push(formatDate(this.mapping.updated_at));
      }
      if (subtitleParts.length === 0) {
        return "";
      }

      return subtitleParts.join(", ");
    },
    headerButtons: function () {
      const buttons = [
        {
          type: "button",
          icon: this.$store.getters["config/icons"].version,
          tooltip: this.$t("general.showVersions"),
          class: "btn-outline-primary",
          emit: "showVersions",
          disabledWhenLoading: true
        }
      ];
      if (this.isPresetProject || !this.isDevPresetVersion) {
        return buttons;
      }
      buttons.push({
        type: "button",
        title: this.$t("general.save"),
        emit: "save",
        disabledWhenLoading: true
      });
      return buttons;
    }
  },
  watch: {
    selectedProject: function () {
      this.loadDataStructures();
      this.getProjectOptions();
    },
    selectedPresetVersion: function () {
      this.loadDataStructures();
      this.getProjectOptions();
    }
  },
  mounted() {
    this.getProjectOptions();
    this.mapping.projects = [this.selectedProject.id];

    this.loadDataStructures();
    this.loadMapping();
  },
  methods: {
    ...mapActions("mapping", [REMOVE_MAPPING_FIELDS]),
    getProjectOptions() {
      // Fills multiselect projects
      const formProjects = Object.values(this.form).find(
        element => element.name === "projects"
      );
      formProjects.options = this.projects?.length
        ? this.projects.map(project => {
            return {
              label: project.name,
              value: project.id
            };
          })
        : [];
    },
    loadMapping() {
      addEventToLoadingQueue({ key: "loadMapping" });
      let mappingId = this.$route.params.id;
      Mapping.get(mappingId)
        .then(response => {
          response.data.projects = response.data.projects.map(
            project => project.id
          );
          this.mapping = response.data;
          removeEventFromLoadingQueue({ key: "loadMapping" });
        })
        .catch(error => {
          this.$error(error);
        });
    },
    getDataStructureOptions(dataStructures) {
      const formDataStructureSource = Object.values(this.form).find(
        element => element.name === "source_data_structure_id"
      );

      const formDataStructureTarget = Object.values(this.form).find(
        element => element.name === "target_data_structure_id"
      );

      const options = dataStructures.map(item => ({
        label: item.label,
        value: item.id
      }));

      formDataStructureSource.options = options;
      formDataStructureTarget.options = options;
    },
    loadDataStructures() {
      addEventToLoadingQueue({ key: "loadDataStructures" });
      let params = {
        noPagination: true
      };

      DataStructures.getAll(params)
        .then(response => {
          this.getDataStructureOptions(response.data);
          removeEventFromLoadingQueue({ key: "loadDataStructures" });
        })
        .catch(error => {
          this.$error(error);
        });
    },
    async saveMapping() {
      const { addWorkNote } = useWorkNote();
      const { data, success } = await addWorkNote(this.mapping);
      if (!success) {
        return;
      }
      this.mapping = data;
      addEventToLoadingQueue({ key: "saveDataStructures" });
      if (
        this.mapping.source_data_structure_id ===
        this.mapping.target_data_structure_id
      ) {
        removeEventFromLoadingQueue({
          key: "saveDataStructures",
          type: "error",
          prefix: "mapping",
          name: "useDifferentDataStructures"
        });

        return;
      }
      this[REMOVE_MAPPING_FIELDS](this.mapping);
      Mapping.update(this.mapping.id, this.mapping)
        .then(response => {
          removeEventFromLoadingQueue({
            key: "saveDataStructures",
            type: "success",
            prefix: "mapping",
            name: "mappingSaved"
          });
          this.$router.push({
            name: "projectMappingsMappings",
            params: { id: response.data.id }
          });
        })
        .catch(error => {
          this.$error(error);
        });
    },
    onShowVersions() {
      this.showVersionsModal = true;
    }
  }
};
</script>
