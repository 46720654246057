<template>
  <div v-if="ready">
    <FormHelper
      ref="form"
      v-model="values"
      class="pb-5"
      :form="form"
      :config="config"
      :no-data-text="$t('mapping.noTransformersAvailable')"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { UPDATE_TRANSFORMERS } from "@/core/services/store/mappingTransformer.module";
import { checkDepending } from "@/components/Mappings/helpers";
import FormHelper from "@/components/Tools/FormHelper/FormHelper.vue";

export default {
  components: { FormHelper },
  props: {},
  data() {
    return {
      transformer: undefined,
      values: {
        collection: [],
        type: "",
        valueCount: "",
        uniqueValues: false
      },
      ready: false,
      form: [
        {
          label: "collectionLabel",
          name: "collection",
          type: "multiselect",
          disableReturnType: true,
          copy: false
        },
        {
          label: "assignmentType",
          name: "type",
          type: "select",
          disableReturnType: true,
          default: 3,
          validations: {
            required: true,
            minLength: 1,
            alphaNumSpace: false
          },
          options: [
            {
              value: 0,
              label: "fixedNumber"
            },
            {
              value: 1,
              label: "multiply"
            },
            {
              value: 2,
              label: "sum"
            },
            {
              value: 3,
              label: "assign"
            }
          ]
        },
        {
          label: "valueCount",
          name: "valueCount",
          type: "number",
          disableReturnType: true,
          strict: false,
          dependsOn: [{ name: "type", values: [0] }]
        },
        {
          label: "uniqueValues",
          name: "uniqueValues",
          type: "checkbox",
          disableReturnType: true,
          dependsOn: [{ name: "type", values: [2] }]
        }
      ],
      config: {
        class: "form-control",
        labelStacked: true,
        snippetPrefix: "mapping",
        enableVariables: false
      }
    };
  },
  computed: {
    ...mapGetters("mapping", {
      library: "transformers",
      collectionTransformerId: "collectionTransformerId",
      findField: "findField"
    }),
    ...mapGetters("mappingTransformer", [
      "transformers",
      "fieldId",
      "fieldStructure",
      "connections",
      "isStatic"
    ]),
    selectedCollection() {
      return (
        this.$store.getters["mapping/mappingFieldTransformers"].filter(
          c => c.id === this.transformer.id
        )[0] ?? ""
      );
    }
  },
  watch: {
    values: {
      deep: true,
      handler: function () {
        if (!this.ready) return;
        this.updateStore();
      }
    }
  },
  mounted() {
    this.setTransformer();
    this.setFormHelperValues();
    this.ready = true;
  },
  methods: {
    ...mapActions("mappingTransformer", [UPDATE_TRANSFORMERS]),
    setTransformer() {
      this.transformer = Object.assign(
        {},
        this.transformers.find(
          t =>
            t.transformer_id === this.collectionTransformerId("Collection") &&
            t.position === -2
        )
      );
    },
    setFormHelperValues() {
      // set saved selection here
      this.values.collection = this.selectedCollection.config.collection;
      this.values.type = this.selectedCollection.config.type;
      this.values.valueCount = this.selectedCollection.config.valueCount;
      this.values.uniqueValues = this.selectedCollection.config.uniqueValues;
      this.form[0].options = this.transformer.config["input"];
    },
    updateStore() {
      Object.keys(this.values).forEach((key, index) => {
        this.transformer.config[key] = Object.values(this.values)[index];
      });
      let transformer = this.transformers.find(
        t =>
          t.transformer_id === this.collectionTransformerId("Collection") &&
          t.position === -2
      );
      let index = this.transformers.indexOf(transformer);
      let transformers = [...this.transformers];
      transformers[index] = transformer;
      this[UPDATE_TRANSFORMERS](transformers);
    },
    checkIfDepends(config) {
      return checkDepending(config, this.values);
    }
  }
};
</script>
