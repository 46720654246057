<template>
  <div class="timeline-item" :class="{ active: isActive }">
    <div class="timeline-content">
      <div class="mb-1">
        <span class="h5">{{
          transformer.label ? transformer.label : $t("mapping.noLabel")
        }}</span>
      </div>
      <div class="row align-items-center mb-1">
        <div class="col-12 pb-0 mb-n3">
          {{ $t("mapping.transformerTransformer") }}
        </div>
        <div class="col-8">
          <FormHelper
            ref="form"
            :key="formKey"
            v-model="transformer"
            :form="form"
            :config="config"
            :no-data-text="$t('mapping.noTransformersAvailable')"
            @change="updateStoreTransformer"
          />
        </div>
        <div class="col-2">
          <button
            v-b-popover.hover.top="$t('mapping.transformerDelete')"
            class="btn btn-secondary btn-text-white btn-block mt-6"
            @click="remove"
          >
            <i class="fal fa-trash" />
          </button>
        </div>
        <div class="col-2">
          <button
            v-b-popover.hover.top="$t('mapping.transformerEdit')"
            class="btn btn-primary btn-text-white btn-block mt-6"
            :disabled="!transformer.transformer_id"
            @click="edit"
          >
            <i class="fal fa-gear" />
          </button>
        </div>
      </div>
      <div class="row mb-1">
        <div class="col-6">
          <div class="d-flex justify-content-start align-items-center mb-1">
            <i class="fal fa-arrow-right-to-arc mr-1" />
            {{
              transformer.config.input.length
                ? transformer.config.input.join(", ")
                : $t("mapping.noInputVars")
            }}
          </div>
          <div class="d-flex justify-content-start align-items-center">
            <i class="fal fa-arrow-right-from-arc mr-1" />
            {{
              transformer.config.output
                ? "\{\{" + transformer.config.output + "\}\}"
                : $t("mapping.noInputVars")
            }}
          </div>
        </div>
        <v-divider :vertical="true" inset />
        <div class="col-6">
          <div class="mb-3">{{ $t("mapping.conditions") }}:</div>
          <code v-if="conditions.length" class="p-0">{{ conditions }}</code>
          <div v-else>{{ $t("mapping.conditionsEmpty") }}</div>
        </div>
        <div class="col-6">
          <div class="mb-3">
            {{ $t("mapping.transformerConfigurationTransformer") }}:
          </div>
          <div v-for="(config, i) in transformer.config" :key="i">
            <span class="badge">{{ i }}</span> : {{ config }}
            <!-- fix empty outout-->
          </div>
        </div>
        <v-divider :vertical="true" inset />
      </div>
    </div>
    <div class="timeline-media cursor-move">
      <i :class="timelineMedia" />
    </div>
  </div>
</template>

<script>
import { validateTransformer } from "@/components/Mappings/validation";
import { mapActions, mapGetters } from "vuex";
import { UPDATE_TRANSFORMER } from "@/core/services/store/mappingTransformer.module";
import { renderConditions } from "@/components/Mappings/helpers";
import FormHelper from "@/components/Tools/FormHelper/FormHelper";

export default {
  components: { FormHelper },
  props: {
    transformerItem: {
      type: Object,
      default: () => {}
    },
    isActive: Boolean,
    formKey: {
      type: Number,
      default: () => 0
    }
  },
  data() {
    return {
      transformer: this.transformerItem,
      values: "",
      selectedTransformer: {},
      condition: "",
      config: {
        class: "form-control",
        labelStacked: true,
        snippetPrefix: "formHelper.test",
        enableVariables: false
      }
    };
  },
  computed: {
    ...mapGetters("mapping", { library: "transformers" }),
    ...mapGetters("mapping", ["findField", "collectionTransformerId"]),
    ...mapGetters("mappingTransformer", ["fieldId", "selectedTransformerId"]),
    timelineMedia: function () {
      let valid = this.checkIsValid();
      if (valid) {
        return "fal fa-check text-success";
      } else {
        return "fal fa-xmark text-danger";
      }
    },
    filteredLibrary: function () {
      let field = this.findField(this.fieldId);
      let isCollection = field.type === "collection";
      let filterIds = [
        this.collectionTransformerId("Collection"),
        this.collectionTransformerId("CollectionFilter")
      ];
      return isCollection
        ? this.library.filter(
            t => t.is_collection_transformer && !filterIds.includes(t.id)
          )
        : this.library.filter(t => !t.is_collection_transformer);
    },
    form: function () {
      let options = [];
      this.filteredLibrary.forEach(transformer => {
        options.push({
          group: transformer.group,
          value: transformer.id,
          label: transformer.label
        });
      });

      return [
        {
          type: "select",
          name: "transformer_id",
          sort: "desc",
          clearable: true,
          options: options
        }
      ];
    },
    conditions: function () {
      return renderConditions(this.transformer.conditions);
    }
  },
  mounted() {},
  methods: {
    ...mapActions("mappingTransformer", [UPDATE_TRANSFORMER]),
    updateStoreTransformer(transformer) {
      this.transformer.transformer_id = transformer.value;
      if (transformer.value === null) {
        this.clear();
        return;
      }

      const payload = {
        id: this.transformer.id,
        transformer: this.transformer
      };
      this[UPDATE_TRANSFORMER](payload);
    },
    edit() {
      this.$emit("edit", this.transformer.id);
    },
    remove() {
      this.$emit("remove", this.transformer.id);
    },
    clear() {
      this.$emit("clear", this.transformer.id);
    },
    checkIsValid() {
      if (!this.selectedTransformer || !this.transformer) return;
      return validateTransformer(this.transformer);
    }
  }
};
</script>

<style lang="scss">
#transformer-timeline {
  .timeline-item {
    border-left: 2px solid #ebedf3;
    padding: 0 0 20px 50px;
    &.active {
      border-color: #e7435e !important;
      border-width: 1px !important;
      .timeline-media {
        border-color: #e7435e !important;
        border-width: 1px !important;
      }
    }
  }
}
</style>
