<script setup>
import {
  computed,
  defineEmits,
  defineProps,
  getCurrentInstance,
  ref,
  watch
} from "vue";

import Backup from "@/components/Backup/backup";
import TableHelper from "@/components/Tools/TableHelper/TableHelper.vue";
import CodeDiff from "vue-code-diff";
import {
  addEventToLoadingQueue,
  removeEventFromLoadingQueue
} from "@/composables/useLoadingQueue";
import Progressbar from "@/components/Tools/Progressbar.vue";
import { formatDate } from "@/components/Tools/helperFunctions";

const i18n = getCurrentInstance().proxy.$i18n;

const props = defineProps({
  value: {
    type: Boolean,
    default: false
  },
  classname: {
    type: String,
    default: ""
  },
  object: {
    type: Object,
    default: () => null
  }
});

const emit = defineEmits(["input"]);

const show = computed({
  get() {
    return props.value;
  },
  set(value) {
    emit("input", value);
  }
});

const backupTable = ref(null);
const items = ref([]);
const header = [
  {
    key: "updated_by_user",
    sortable: true,
    label: i18n.t("general.resourceOwner")
  },
  {
    key: "updated_at",
    label: i18n.t("table.updatedAt"),
    sortable: true,
    thStyle: { width: "160px" }
  }
];

const backup = ref({});
const title = computed(() => {
  if (
    props.object !== null &&
    props.object.label !== undefined &&
    props.object.label !== null &&
    props.object.label.length > 0
  ) {
    return props.object.label;
  } else if (
    props.object !== null &&
    props.object.name !== undefined &&
    props.object.name !== null &&
    props.object.name.length > 0
  ) {
    return props.object.name;
  }
  return i18n.t("general.versions");
});
const subtitle = computed(() => {
  if (!props.object) {
    return "";
  }

  let subtitleParts = [];
  if (props.object.updated_by_user?.full_name) {
    subtitleParts.push(props.object.updated_by_user?.full_name);
  }
  if (props.object.updated_at) {
    subtitleParts.push(formatDate(props.object.updated_at));
  }
  if (subtitleParts.length === 0) {
    return "";
  }

  return subtitleParts.join(", ");
});
const oldContent = computed(() => {
  if (
    backup.value?.old_content === undefined ||
    backup.value?.old_content === null
  ) {
    return "";
  }
  return JSON.stringify(JSON.parse(backup.value.old_content), null, 4);
});
const newContent = computed(() => {
  if (
    backup.value?.new_content === undefined ||
    backup.value?.new_content === null
  ) {
    return "";
  }
  return JSON.stringify(JSON.parse(backup.value.new_content), null, 4);
});

watch(
  () => props.value,
  newValue => {
    // props.value changes only when the modal is opened or closed.
    // When opening the data should be reloaded
    if (!newValue) {
      return;
    }
    loadVersions();
  }
);

function loadVersions() {
  backup.value = null;
  items.value = [];
  addEventToLoadingQueue({ key: "backup", progressBarKey: "backup" });
  Backup.show(props.classname, props.object.id.toString())
    .then(response => {
      items.value = response.data.sort((a, b) =>
        a.updated_at > b.updated_at ? -1 : 1
      );
    })
    .finally(() => {
      if (items.value.length > 0) {
        backupTable.value.selectSingleRow(true, 0);
        return;
      }
      removeEventFromLoadingQueue({ key: "backup" });
    });
}

function onSelectRow(data) {
  backup.value = null;
  if (data.length < 1) {
    return;
  }
  addEventToLoadingQueue({ key: "backup", progressBarKey: "backup" });
  Backup.showBackup(
    props.classname,
    props.object.id.toString(),
    data[0].filename
  )
    .then(response => {
      backup.value = response.data;
    })
    .finally(() => {
      removeEventFromLoadingQueue({ key: "backup" });
    });
}
</script>

<template>
  <v-dialog id="backup-modal" v-model="show" content-class="bg-white">
    <div class="card card-custom overflow-hidden backup-card">
      <div class="card-header align-content-center">
        <div class="d-flex flex-column">
          <span class="text-h5">
            {{ title }}
          </span>
          <span class="text-muted">
            {{ subtitle }}
          </span>
        </div>
        <div class="card-toolbar">
          <button size="sm" class="btn btn-secondary" @click="show = false">
            {{ $t("general.close") }}
          </button>
        </div>
      </div>
      <div class="card-body overflow-auto">
        <div class="row">
          <div class="col pa-0">
            <Progressbar key="backup" progress-bar-key="backup" />
          </div>
        </div>
        <div class="row">
          <div class="col-8 col-xl-9">
            <code-diff
              :old-string="oldContent"
              :new-string="newContent"
              :context="5"
              output-format="side-by-side"
            />
          </div>
          <div class="col-4 col-xl-3">
            <TableHelper
              ref="backupTable"
              loading-key="backup"
              progress-bar-key="backup"
              :items="items"
              :fields="header"
              :disable-page-size="true"
              :enable-pagination="false"
              :no-route-params="true"
              :disable-search="true"
              select-mode="single"
              @reload-data="loadVersions"
              @select-row="onSelectRow"
            />
          </div>
        </div>
      </div>
    </div>
  </v-dialog>
</template>

<style scoped lang="scss">
.backup-card {
  height: 90vh;
}

:deep(code) {
  &.hljs {
    background: transparent;
    color: $color-connect-text;
    font-family: $body-font-family;
    font-size: 0.85rem;
    padding: 0;
  }
}
</style>
