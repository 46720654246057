<template>
  <div @click="contextMenu.visible = false">
    <Header
      :show-back-button="true"
      :title="$t('dataStructures.edit')"
      :subtitle="subtitle"
      :items="headerButtons"
      @back="back"
      @save="save"
      @showVersions="onShowVersions"
    />
    <b-overlay
      :show="isBusy > 0"
      class="flex-grow-1 mt-3"
      opacity="1"
      bg-color="white"
      :rounded="true"
      no-fade
    >
      <template #overlay>
        <div class="d-flex flex-column align-center">
          <div class="spinner spinner-primary spinner-lg"></div>
        </div>
      </template>
      <div class="d-flex mb-3">
        <div class="col-auto pl-0 pt-0">
          <Toolbox
            :structure-fields="structureFields"
            :data-fields="dataFields"
            :field-type-icons="fieldTypeIcons"
            @drag-start="dragStart"
          />
        </div>
        <div class="col-4 px-0 pt-0">
          <div class="">
            <div class="card card-custom">
              <div class="card-header pa-4">
                <div class="card-title">
                  <h3 class="card-label">{{ $t("dataStructures.title") }}</h3>
                </div>
              </div>
              <div class="p-4 pb-0">
                <b-button
                  variant="outline-secondary"
                  style="line-height: 0.5"
                  class="mr-2 mb-2"
                  size="sm"
                  @click="toggleSlTreeNodes(false)"
                  >{{ $t("dataStructures.collapse") }}
                </b-button>
                <b-button
                  variant="outline-secondary"
                  style="line-height: 0.5"
                  class="mb-2"
                  size="sm"
                  @click="toggleSlTreeNodes(true)"
                  >{{ $t("dataStructures.expand") }}
                </b-button>
              </div>
              <perfect-scrollbar>
                <div class="card-body pa-4">
                  <sl-vue-tree
                    id="slVueTree"
                    ref="slVueTree"
                    v-model="nodes"
                    :allow-multiselect="false"
                    style="max-height: calc(100vh - 250px)"
                    @drop="onNodeDrop"
                    @externaldrop="onExternalDropHandler"
                    @nodecontextmenu="showContextMenu"
                    @input="input"
                  >
                    <template #title="{ node }">
                      <i
                        v-if="node.isLeaf"
                        :class="getFieldTypeIcon(node)"
                        class="min-width-20"
                      />
                      {{ node.data.label }}
                      <span v-if="node.isLeaf" class="text-muted">
                        <em> - {{ node.data.type }}</em>
                      </span>
                    </template>

                    <template #toggle="{ node }">
                      <i
                        :class="getFieldTypeIcon(node)"
                        style="cursor: pointer"
                        class="min-width-20"
                      />
                    </template>

                    <template #sidebar="{ node }">
                      <span class="visible-icon pr-1">
                        <i
                          v-if="nodeIsPrimary(node.data)"
                          v-b-popover.hover.top="$t('dataStructures.primary')"
                          class="fa fa-key mr-2 icon-primary"
                        />
                        <i
                          v-if="config.invalidFields.includes(node.data.id)"
                          v-b-popover.hover.top="
                            getInvalidTooltipText(node.data)
                          "
                          class="fa fa-exclamation mr-2 icon-error"
                        />
                        <i
                          v-if="node.isLeaf"
                          v-b-popover.hover.top="$t('dataStructures.index')"
                          class="fa fa-circle icon-sm"
                          :class="getCircleClass(node, 'index')"
                        />
                        <i
                          v-if="node.isLeaf"
                          v-b-popover.hover.top="$t('dataStructures.unique')"
                          class="fa fa-circle icon-sm ml-1"
                          :class="getCircleClass(node, 'unique')"
                        />
                        <i
                          v-if="node.data.type !== 'root'"
                          v-b-popover.hover.top="$t('dataStructures.nullable')"
                          class="fa fa-circle icon-sm ml-1"
                          :class="getCircleClass(node, 'nullable')"
                        />
                        <i
                          v-if="node.isLeaf"
                          v-b-popover.hover.top="
                            $t('dataStructures.showInView')
                          "
                          class="fa fa-circle icon-sm ml-1"
                          :class="getCircleClass(node, 'show_in_view')"
                        />
                        <i
                          v-if="node.isLeaf"
                          v-b-popover.hover.top="
                            $t('dataStructures.skipOnDirtyCheck')
                          "
                          class="fa fa-circle icon-sm ml-1"
                          :class="getCircleClass(node, 'skip_on_dirty_check')"
                        />
                      </span>
                    </template>

                    <template #draginfo>
                      <span
                        v-if="selectedNode && selectedNode.data"
                        class="bg-white"
                      >
                        {{ selectedNode.data.label }}
                      </span>
                    </template>
                  </sl-vue-tree>
                </div>
              </perfect-scrollbar>

              <!-- Tree context menu start-->
              <ContextMenu
                v-show="contextMenu.visible"
                class="context-menu-position"
                :items="contextMenu.items"
                @addNode="onAddNodeClick"
                @setPrimary="setPrimary"
                @setContentIdentifier="setContentIdentifier"
                @cloneField="cloneField"
                @copyField="copyField"
                @pasteField="pasteField"
                @removeNode="removeNode"
              />
              <!-- Tree context menu end -->
            </div>
          </div>
        </div>

        <div class="col pr-0 pt-0">
          <div class="card card-custom">
            <div class="card-body">
              <FormHelper
                v-if="
                  selectedNode &&
                  selectedNode.data &&
                  selectedNode.data.type !== 'root'
                "
                v-model="configFormValues"
                :config="configFormOptions"
                :form="configForm"
                @change="onConfigFormChanged"
              />
            </div>
          </div>
        </div>
      </div>
    </b-overlay>

    <BackupModal
      v-if="dataStructure"
      v-model="showVersionsModal"
      :object="dataStructure"
      classname="DataStructure"
    />
  </div>
</template>

<script>
import Toolbox from "@/components/DataStructures/Editor/Toolbox.vue";
import { cloneDeep, generateHash } from "@/components/Tools/helperFunctions";
import { mapGetters } from "vuex";
import Header from "@/components/Tools/Header/Header.vue";
import DataStructures from "@/components/DataStructures/dataStructures";
import FormHelper from "@/components/Tools/FormHelper/FormHelper.vue";
import ContextMenu from "@/components/Tools/ContextMenu/ContextMenu.vue";
import {
  addEventToLoadingQueue,
  removeEventFromLoadingQueue
} from "@/composables/useLoadingQueue";
import { formatDate } from "@/components/Tools/helperFunctions";
import BackupModal from "@/components/Backup/BackupModal.vue";
import { useWorkNote } from "@/composables/useWorkNote";

const FIELD_TYPE_ICONS = {
  root: "fal fa-folder",
  rootExpanded: "fal fa-folder-open",
  fieldset: "fal fa-folder",
  fieldsetExpanded: "fal fa-folder-open",
  collection: "fal fa-rectangle",
  collectionExpanded: "fal fa-rectangle-history",
  dynamic: "fal fa-cubes",
  int: "fal fa-sort-numeric-down",
  float: "fal fa-sort-numeric-down",
  string: "fal fa-edit",
  text: "fal fa-edit",
  bool: "fal fa-check-square",
  json: "fal fa-code",
  datetime: "fal fa-calendar",
  date: "fal fa-calendar",
  time: "fal fa-clock",
  relation: "fal fa-link",
  array: "fal fa-list"
};

const CONFIG_FIELDS = [
  "name",
  "original_name",
  "label",
  "description",
  "validation_notice",
  "type",
  "index",
  "unique",
  "nullable",
  "show_in_view",
  "skip_on_dirty_check",
  "regex"
];

export default {
  components: { BackupModal, FormHelper, Header, Toolbox, ContextMenu },
  props: {
    dataStructureId: {
      type: Number,
      default: null
    }
  },
  data() {
    return {
      isBusy: 0,
      dataStructure: {
        classname: "Datenstruktur"
      },
      isStandardDataStructure: false,
      fieldTypes: [],
      structureFields: [],
      dataFields: [],
      fieldTypeIcons: FIELD_TYPE_ICONS,
      data: [],
      nodes: [],
      prevNodes: [],
      config: {
        update: true,
        primary: "",
        contentIdentifier: "",
        invalidFields: []
      },
      contextMenu: {
        visible: false,
        node: {},
        toCopy: null,
        items: [],
        left: 0,
        top: 0
      },
      dragItem: null,
      sticky: 0,
      inputFocus: false,
      configFormOptions: {
        title: this.$t("dataStructures.properties"),
        snippetPrefix: "dataStructures",
        enableVariables: false
      },
      configForm: [],
      configFormValues: {},
      dataStructures: [],
      showVersionsModal: false
    };
  },
  computed: {
    ...mapGetters([
      "dataStructureCreated",
      "selectedProject",
      "selectedPresetVersion",
      "isPresetProject",
      "isDevPresetVersion"
    ]),
    headerButtons: function () {
      let headerButtons = [
        {
          type: "button",
          icon: this.$store.getters["config/icons"].version,
          class: "btn-outline-primary",
          emit: "showVersions",
          tooltip: this.$t("general.showVersions"),
          disabledWhenLoading: true
        }
      ];
      if (this.isPresetProject || !this.isDevPresetVersion) {
        return headerButtons;
      }
      headerButtons.push({
        type: "button",
        title: this.$t("general.save"),
        emit: "save",
        disabledWhenLoading: true
      });
      return headerButtons;
    },
    subtitle: function () {
      if (!this.dataStructure) {
        return "";
      }

      let subtitleParts = [];
      if (this.dataStructure.updated_by_user?.full_name) {
        subtitleParts.push(this.dataStructure.updated_by_user?.full_name);
      }
      if (this.dataStructure.updated_at) {
        subtitleParts.push(formatDate(this.dataStructure.updated_at));
      }
      if (subtitleParts.length === 0) {
        return "";
      }

      return subtitleParts.join(", ");
    },
    selectedNode: function () {
      return this.findSelected(this.nodes);
    },
    structureFieldsNames: function () {
      return this.structureFields.map(d => d.name);
    },
    dynamicFieldAllowedFields: function () {
      if (!this.selectedNode) return [];

      // find parent collection of selected node
      let parentCollectionSelected = this.findParentCollection(
        this.selectedNode.data
      );

      return this.data
        .filter(field => {
          // field is not allowed if field is selectedField or field is collection or set
          if (
            field.id === this.selectedNode.data.id ||
            this.structureFieldsNames.includes(field.type)
          )
            return false;

          // find collection of field
          let parentCollectionField = this.findParentCollection(field);

          // field is allowed if not in collection or in same collection like selectedField
          return (
            !parentCollectionField ||
            (parentCollectionSelected &&
              parentCollectionField.id === parentCollectionSelected.id)
          );
        })
        .map(d => ({ id: d.id, name: d.full_name }));
    },
    dynamicFieldPreview: function () {
      if (this.selectedNode.data.type !== "dynamic") return "";

      const fields = this.selectedNode.data.config.find(
        f => f.name === "values"
      );
      if (!fields) return "";
      if (fields.value === undefined) fields.value = [];

      const glue = this.selectedNode.data.config.find(f => f.name === "glue");
      if (!glue) return "";
      if (glue.value === undefined) glue.value = "";

      return fields.value.join(glue.value);
    }
  },
  watch: {
    selectedNode: function () {
      this.buildConfigForm();
    },
    selectedPresetVersion: function () {
      this.loadFieldTypes();
    }
  },
  mounted() {
    // handle key events
    window.addEventListener("keydown", this.onKeyDownHandler);

    this.loadFieldTypes();
  },
  beforeDestroy() {
    window.removeEventListener("keydown", this.onKeyDownHandler);
  },
  methods: {
    buildConfigForm() {
      if (!this.selectedNode) {
        return;
      }
      this.configFormValues = {
        name: this.selectedNode.data.name,
        label: this.selectedNode.data.label,
        description: this.selectedNode.data.description,
        nullable: this.selectedNode.data.nullable
      };
      this.configForm = [
        {
          name: "name",
          label: "name",
          type: "text",
          orderIndex: 0
        },
        {
          name: "label",
          label: "label",
          type: "text",
          orderIndex: 2
        },
        {
          name: "description",
          label: "description",
          type: "textarea",
          orderIndex: 3
        },
        {
          name: "nullable",
          label: "nullable",
          type: "checkbox",
          default: this.selectedNode.data.nullable,
          orderIndex: 9
        }
      ];

      if (this.selectedNode.data.original_name) {
        this.configFormValues.original_name =
          this.selectedNode.data.original_name;
        this.configForm.push({
          name: "original_name",
          label: "originalName",
          type: "text",
          orderIndex: 1,
          disabled: true
        });
      }

      if (this.isAllowedToBeShownInView(this.selectedNode.data)) {
        this.configFormValues.show_in_view =
          this.selectedNode.data.show_in_view;
        this.configForm.push({
          name: "show_in_view",
          label: "showInView",
          type: "checkbox",
          default: this.selectedNode.data.show_in_view,
          orderIndex: 10
        });
      }

      if (this.selectedNode.isLeaf) {
        this.configFormValues.type = this.selectedNode.data.type;
        this.configForm.push({
          name: "type",
          label: "type",
          type: "select",
          orderIndex: 4,
          options: this.fieldTypes.data_fields
        });

        this.configFormValues.regex = this.selectedNode.data.regex;
        this.configForm.push({
          name: "regex",
          label: "regex",
          type: "text",
          orderIndex: 4.5,
          dependsOn: [
            {
              name: "type",
              values: ["string", "text"]
            }
          ],
          help: this.$t("general.example", { example: "/^.+@.+$/i" })
        });
        this.configFormValues.validation_notice =
          this.selectedNode.data.validation_notice;
        this.configForm.push({
          name: "validation_notice",
          label: "validationNotice",
          help: "validationNoticeHelp",
          type: "text",
          orderIndex: 4.55,
          dependsOn: [
            {
              name: "type",
              values: ["string", "text"]
            }
          ]
        });

        this.configFormValues.index = this.selectedNode.data.index;
        this.configForm.push({
          name: "index",
          label: "index",
          type: "checkbox",
          default: this.selectedNode.data.index,
          orderIndex: 7
        });

        this.configFormValues.unique = this.selectedNode.data.unique;
        this.configForm.push({
          name: "unique",
          label: "unique",
          type: "checkbox",
          default: this.selectedNode.data.unique,
          orderIndex: 8
        });

        this.configFormValues.skip_on_dirty_check =
          this.selectedNode.data.skip_on_dirty_check;
        this.configForm.push({
          name: "skip_on_dirty_check",
          label: "skipOnDirtyCheck",
          type: "checkbox",
          default: this.selectedNode.data.skip_on_dirty_check,
          orderIndex: 11
        });

        if (this.isAllowedAsPrimary(this.selectedNode.data)) {
          this.configFormValues.primary = this.nodeIsPrimary(
            this.selectedNode.data
          );
          this.configForm.push({
            name: "primary",
            label: "primary",
            type: "checkbox",
            orderIndex: 5,
            options: this.fieldTypes.data_fields
          });
        }

        if (this.isAllowedAsContentIdentifier(this.selectedNode.data)) {
          this.configFormValues.contentIdentifier =
            this.nodeIsContentIdentifier(this.selectedNode.data);
          this.configForm.push({
            name: "contentIdentifier",
            label: "contentIdentifier",
            type: "checkbox",
            default: this.nodeIsContentIdentifier(this.selectedNode.data),
            orderIndex: 6
          });
        }
      }

      this.selectedNode.data.config?.forEach((config, index) => {
        const name = "config_" + config.name;
        this.configFormValues[name] = config.value;
        let field = {
          name: name,
          label: config.name,
          type: this.castType(config.type),
          orderIndex: 12 + index
        };
        if (field.type === "multiselect") {
          field.options = this.dynamicFieldAllowedFields;
        } else if (field.name === "config_related_data_structure") {
          field.options = this.dataStructures;
        } else if (field.type === "select") {
          field.options = config.options;
        }

        this.configForm.push(field);
      });

      if (this.selectedNode.data.type === "dynamic") {
        this.configFormValues.dynamicFieldPreview = this.dynamicFieldPreview;
        this.configForm.push({
          name: "dynamicFieldPreview",
          label: "dynamicFieldPreview",
          type: "text",
          disabled: true
        });
      }

      if (this.isStandardDataStructure) {
        for (let key in this.configForm) {
          this.configForm[key].disabled = true;
        }
      }

      this.configForm.sort((a, b) => a.orderIndex - b.orderIndex);
    },
    castType(type) {
      switch (type) {
        case "int":
          return "number";
        case "string":
          return "text";
        case "array":
          return "multiselect";
        default:
          return type;
      }
    },
    onConfigFormChanged(data) {
      if (CONFIG_FIELDS.includes(data.name)) {
        this.selectedNode.data[data.name] = data.value;
      } else if (data.name === "primary") {
        this.changePrimary(this.selectedNode.data);
      } else if (data.name === "contentIdentifier") {
        this.changeContentIdentifier(this.selectedNode.data);
      } else if (data.name.startsWith("config_")) {
        const fieldName = data.name.slice("config_".length);
        let field = this.selectedNode.data.config.find(
          f => f.name === fieldName
        );
        field.value = data.value;

        if (this.selectedNode.data.type === "dynamic") {
          this.configFormValues.dynamicFieldPreview = this.dynamicFieldPreview;
        }
      }
      this.validateFields();
    },
    toggleSlTreeNodes(type) {
      const slVueTree = this.$refs.slVueTree;
      slVueTree.traverse((node, nodeModel, path) => {
        path.forEach(entry => {
          if (entry.isRootNode) {
            return;
          }
          entry.isExpanded = type;
        });
      });
    },
    getFieldTypeIcon(node) {
      if (node.isLeaf) {
        return this.fieldTypeIcons[node.data.type];
      }
      let iconName = node.data.type;
      if (node.isExpanded) {
        iconName += "Expanded";
      }
      return this.fieldTypeIcons[iconName];
    },
    onKeyDownHandler(event) {
      const keyCode = event.code;
      const allowedKeys = ["ArrowUp", "ArrowDown", "Space", "Enter"];
      if (!allowedKeys.includes(keyCode)) return;
      const slVueTree = this.$refs.slVueTree;
      if (slVueTree === undefined) return;

      const selectedNode = slVueTree.getSelected()[0];
      if (selectedNode === undefined) return;

      let nodeToSelect;
      if (keyCode === "ArrowDown") {
        nodeToSelect = slVueTree.getNextNode(
          selectedNode.path,
          node => node.isVisible
        );
      } else if (keyCode === "ArrowUp") {
        nodeToSelect = slVueTree.getPrevNode(
          selectedNode.path,
          node => node.isVisible
        );
      } else if (keyCode === "Enter" || keyCode === "Space") {
        if (selectedNode.isLeaf) return;
        if (!this.inputFocus) {
          slVueTree.updateNode(selectedNode.path, {
            isExpanded: !selectedNode.isExpanded
          });
        }
      }

      if (!nodeToSelect) return;

      slVueTree.select(nodeToSelect.path);
    },
    findParentCollection(data) {
      // search for the parent collection of a field
      let parentCollection = this.data.find(d => d.id === data.parent_id);
      while (parentCollection && parentCollection.type !== "collection") {
        parentCollection = this.data.find(
          d => d.id === parentCollection.parent_id
        );
      }
      return parentCollection;
    },
    loadFieldTypes() {
      this.isBusy++;
      DataStructures.getFieldTypes()
        .then(response => {
          this.fieldTypes = response.data;
          this.createToolboxItems();
          this.isBusy--;

          let id = this.$route.params.id;
          if (id !== undefined) {
            this.loadDataStructure(id);
            return;
          }
          this.config.update = false;
          this.dataStructure = this.dataStructureCreated;
          if (this.dataStructure.fields === undefined) {
            this.dataStructure.fields = [];
          }
          this.setNewIds(this.dataStructure.fields);
          this.setNode();
        })
        .catch(error => {
          this.isBusy--;
          this.showToast(error, "error");
        });
    },
    loadDataStructure(id) {
      this.isBusy++;
      DataStructures.get(id)
        .then(response => {
          this.dataStructure = response.data;
          this.dataStructure.tablename = this.dataStructure.raw_tablename;
          this.dataStructure.projects = this.dataStructure.projects.map(
            project => project.id
          );
          this.disableStandardDataStructure();
          this.setNode();
          this.isBusy--;
          this.loadDataStructures();
        })
        .catch(error => {
          this.isBusy--;
          this.showToast(error, "error");
          this.back();
        });
    },
    disableStandardDataStructure() {
      this.isStandardDataStructure =
        this.dataStructure.is_standard_data_structure;

      if (!this.isStandardDataStructure || this.headerButtons.length < 2) {
        return;
      }
      this.headerButtons[1].disabled =
        this.dataStructure.is_standard_data_structure;
    },
    getDataStructureOptions(dataStructures) {
      const options = dataStructures.map(item => ({
        label: item.label,
        value: item.id
      }));

      this.dataStructures = options;
    },
    loadDataStructures() {
      addEventToLoadingQueue({ key: "loadDataStructures" });
      let params = {
        noPagination: true
      };
      let filter = [
        {
          key: "id",
          op: "notEquals",
          value: this.dataStructure.id
        }
      ];

      if (this.dataStructure.projects?.length) {
        filter.push({
          key: "project_id",
          op: "equals",
          value: this.dataStructure.projects
        });
      }

      DataStructures.getAll(params, filter)
        .then(response => {
          this.getDataStructureOptions(response.data);
          removeEventFromLoadingQueue({ key: "loadDataStructures" });
        })
        .catch(error => {
          this.$error(error);
        });
    },
    setNewIds(fields) {
      fields.forEach(field => {
        const oldId = field.id;
        field.id = generateHash();

        let children = fields.filter(f => f.parent_id === oldId);
        children.forEach(c => {
          c.parent_id = field.id;
        });
      });
    },
    setNode() {
      this.isBusy++;
      this.data = [];
      this.nodes = [];

      if (this.dataStructure.fields === undefined) {
        this.dataStructure.fields = [];
      }

      let rootNode = {
        data: { id: null, label: this.dataStructure.label, type: "root" },
        isDraggable: false,
        isLeaf: false,
        isRootNode: true,
        children: []
      };

      const fields = this.dataStructure.fields
        .filter(f => f.parent_id === null)
        .sort(function (a, b) {
          return a.order_index - b.order_index;
        });
      this.setNodes(fields, rootNode);

      this.setNodePrimary(rootNode);

      this.nodes = [rootNode];
      this.isBusy--;
    },
    setNodes(fields, parentNode) {
      fields.forEach(field => {
        field.id = field.id ?? generateHash();
        field.parent_id = field.parent_id ?? parentNode.data.id;
        if (field.parent_id === undefined) field.parent_id = null;
        let node = {
          isLeaf: !this.structureFieldsNames.includes(field.type),
          isDraggable: this.isStandardDataStructure,
          data: field,
          isExpanded: this.structureFieldsNames.includes(field.type),
          children: []
        };

        parentNode.children.push(node);
        this.data.push(field);

        const childFields = this.dataStructure.fields
          .filter(f => f.parent_id === field.id)
          .sort(function (a, b) {
            return a.order_index - b.order_index;
          });
        this.setNodes(childFields, node);
      });
    },
    setNodePrimary(rootNode) {
      if (
        this.dataStructure.primary === undefined ||
        this.dataStructure.primary === null
      )
        return;
      const primaryElements = this.dataStructure.primary.split(".");
      const primaryText = primaryElements.pop();

      let primaryParent = rootNode;
      for (const e of primaryElements) {
        let fieldData = this.data.find(
          d => d.name === e && d.parent_id === primaryParent.data.id
        );
        if (!fieldData) {
          primaryParent = this.createNode(
            {
              id: generateHash(),
              name: e,
              type: "fieldset",
              label: e,
              description: "",
              parent: primaryParent.data.id,
              config: []
            },
            false,
            primaryParent
          );
          continue;
        }
        fieldData.type = "fieldset";
        let node = this.findNodeById(fieldData.id, rootNode.children);
        node.isLeaf = false;
        node.isExpanded = true;
        primaryParent = node;
      }

      const primary = this.data.find(
        d => d.name === primaryText && d.parent_id === primaryParent.data.id
      );
      if (primary) {
        this.config.primary = primary.id;
        primary.unique = true;
        primary.index = true;
        primary.nullable = false;
        return;
      }
      this.config.primary = this.createNode(
        {
          id: generateHash(),
          name: primaryText,
          type: "string",
          label: primaryText,
          description: "",
          unique: true,
          index: true,
          show_in_view: false,
          skip_on_dirty_check: false,
          nullable: false,
          parent_id: primaryParent.data.id,
          config: []
        },
        true,
        primaryParent
      ).data.id;
    },
    createNode(data, isLeaf, parentNode) {
      const node = {
        isLeaf: isLeaf,
        isExpanded: !isLeaf,
        data: data,
        children: []
      };
      parentNode.children.push(node);
      this.data.push(data);

      return node;
    },
    createToolboxItems() {
      this.structureFields = this.fieldTypes.structure_fields.map(d => {
        return {
          isLeaf: false,
          name: d.name,
          type: d.name,
          label: d.name,
          icon: d.name + "Expanded",
          description: "",
          config: d.config ?? [],
          nullable: false
        };
      });

      this.dataFields = this.fieldTypes.data_fields.map(d => {
        return {
          isLeaf: true,
          name: d.name,
          type: d.name,
          label: d.name,
          description: "",
          config: d.config ?? [],
          index: false,
          unique: false,
          nullable: false,
          show_in_view: false,
          skip_on_dirty_check: false
        };
      });
    },
    findSelected(tree) {
      for (const node of tree) {
        if (node.isSelected) {
          node.data =
            this.data.find(d => node.data && d.id === node.data.id) ??
            node.data;
          return node;
        }

        if (node.children) {
          let selectedNode = this.findSelected(node.children);
          if (selectedNode) return selectedNode;
        }
      }
      return null;
    },
    findNodeById(id, tree) {
      for (const node of tree) {
        if (node.data.id === id) {
          return node;
        }

        if (node.children) {
          let selectedNode = this.findNodeById(id, node.children);
          if (selectedNode) return selectedNode;
        }
      }
      return null;
    },
    dragStart(data) {
      if (this.isStandardDataStructure) {
        return;
      }
      this.dragItem = data;
    },
    onNodeDrop(draggingNodes, cursorPosition) {
      if (
        cursorPosition.placement !== "inside" &&
        cursorPosition.node.level === 1
      ) {
        this.nodes = cloneDeep(this.prevNodes);
        return;
      }
      draggingNodes.forEach(node => {
        let data = this.data.find(d => d.id === node.data.id);
        if (data) {
          let parent_id =
            cursorPosition.placement === "inside"
              ? cursorPosition.node.data.id
              : cursorPosition.node.data.parent_id;

          if (data.name === "dynamic") {
            return;
          }

          this.$set(data, "parent_id", parent_id);
        }
      });
      // if the primary field is dragged into a collection, the primary must be removed
      this.resetPrimary(draggingNodes);
      this.validateFields();
    },
    resetPrimary(nodes) {
      nodes.forEach(n => {
        let data = this.data.find(d => d.id === n.data.id);
        if (this.nodeIsPrimary(data) && this.findParentCollection(data)) {
          this.config.primary = "";
          this.$set(data, "unique", false);
          this.$set(data, "index", false);
        }
        this.resetPrimary(n.children ?? []);
      });
    },
    onExternalDropHandler(cursorPosition) {
      if (
        cursorPosition.placement !== "inside" &&
        cursorPosition.node.level === 1
      ) {
        return;
      }
      const data = cloneDeep(this.dragItem);
      data.id = generateHash();
      data.parent_id =
        cursorPosition.placement === "inside"
          ? cursorPosition.node.data.id
          : cursorPosition.node.data.parent_id;

      if (data.name === "dynamic") {
        data.parent_id = null;
        cursorPosition.node = this.$refs.slVueTree.nodes[0];
      }

      data.config.forEach(c => {
        c.value = c.type === "array" ? [] : null;
      });
      this.data.push(data);
      this.$refs.slVueTree.insert(cursorPosition, {
        isLeaf: this.dragItem.isLeaf,
        isExpand: !this.dragItem.isLeaf,
        children: [],
        data: data
      });
      this.validateFields();
    },
    showContextMenu(node, event) {
      if (node && this.isStandardDataStructure) {
        return;
      }
      event.preventDefault();

      this.contextMenu.items = [];

      this.contextMenu.items = this.getContextMenuItems(node);

      this.contextMenu.node = node;
      this.$refs.slVueTree.select(node.path);

      this.contextMenu.visible = true;

      this.$nextTick().then(() => {
        this.contextMenu.top = event.clientY + "px";
        this.contextMenu.left = event.clientX + "px";
      });
    },
    getContextMenuItems(node) {
      let items = [];
      if (!node.isLeaf) {
        let addField = {
          label: this.$t("dataStructures.add"),
          icon: "fal fa-plus",
          emit: "add",
          children: [],
          orderIndex: 1
        };

        this.structureFields.concat(this.dataFields).forEach(field => {
          if (field.name !== "dynamic" || node.data.type === "root") {
            addField.children.push({
              label: field.label,
              icon: this.fieldTypeIcons[field.type],
              emit: "addNode",
              field: field
            });
          }
        });
        items.push(addField);
      } else if (node.isLeaf) {
        if (this.isAllowedAsPrimary(node.data)) {
          items.push({
            label: this.$t("dataStructures.setPrimary"),
            icon: "fal fa-key",
            emit: "setPrimary",
            orderIndex: 2
          });
        }
        if (this.isAllowedAsContentIdentifier(node.data)) {
          items.push({
            label: this.$t("dataStructures.setContentIdentifier"),
            icon: "fal fa-database",
            emit: "setContentIdentifier",
            orderIndex: 3
          });
        }
      }
      if (node.data.type !== "root") {
        items.push({
          label: this.$t("dataStructures.clone"),
          icon: "fal fa-clone",
          emit: "cloneField",
          orderIndex: 4
        });
        items.push({
          label: this.$t("dataStructures.copy"),
          icon: "fal fa-copy",
          emit: "copyField",
          orderIndex: 5
        });
        items.push({
          label: this.$t("general.delete"),
          icon: "fal fa-trash",
          emit: "removeNode",
          orderIndex: 7
        });
      }
      if (this.contextMenu.toCopy) {
        items.push({
          label: this.$t("dataStructures.paste"),
          icon: "fal fa-paste",
          emit: "pasteField",
          orderIndex: 6
        });
      }
      items.sort((a, b) => a.orderIndex - b.orderIndex);
      return items;
    },
    onAddNodeClick(item) {
      this.addNode(item.field);
    },
    input() {
      if (this.nodes.length > 1 || this.isStandardDataStructure) return;
      this.prevNodes = cloneDeep(this.nodes);
    },
    addNode(item) {
      if (!this.selectedNode.children)
        this.$set(this.selectedNode, "children", []);
      let field = {
        data: cloneDeep(item)
      };
      field.data.id = generateHash();
      field.data.config.forEach(c => {
        c.value = c.type === "array" ? [] : null;
      });
      field.data.parent_id = this.selectedNode.data.id;
      field.isLeaf = field.data.isLeaf;
      if (!field.isLeaf) {
        field.children = [];
        field.isExpanded = true;
      }
      field.children = [];
      this.data.push(field.data);
      this.selectedNode.children.push(field);
      this.validateFields();
    },
    removeNode() {
      this.contextMenu.visible = false;
      this.removeData(this.selectedNode);
      const $slVueTree = this.$refs.slVueTree;
      const paths = $slVueTree.getSelected().map(node => node.path);
      $slVueTree.remove(paths);

      this.validateFields();
    },
    removeData(node) {
      const index = this.data.indexOf(
        this.data.find(d => d.id === node.data.id)
      );
      if (index >= 0) {
        this.data.splice(index, 1);
      }
      if (node.children) {
        node.children.forEach(child => {
          this.removeData(child);
        });
      }
    },
    isAllowedAsPrimary(data) {
      return !this.findParentCollection(data);
    },
    isAllowedToBeShownInView(data) {
      return !this.findParentCollection(data);
    },
    setPrimary() {
      let oldPrimary = this.data.find(d => d.id === this.config.primary);
      if (oldPrimary) {
        oldPrimary.index = false;
        oldPrimary.unique = false;
      }
      this.config.primary = this.selectedNode.data.id;
      this.configFormValues.primary = true;
      this.selectedNode.data.index = true;
      this.configFormValues.index = true;
      this.selectedNode.data.unique = true;
      this.configFormValues.unique = true;
      this.selectedNode.data.nullable = false;
      this.configFormValues.nullable = false;
    },
    changePrimary(data) {
      if (this.nodeIsPrimary(data)) {
        this.config.primary = "";
        data.index = false;
        this.configFormValues.index = false;
        data.unique = false;
        this.configFormValues.unique = false;
        return;
      }
      this.setPrimary(data);
    },
    nodeIsPrimary(nodeData) {
      return nodeData.id === this.config.primary;
    },
    checkPrimary() {
      return this.data.some(field => field.id === this.config.primary);
    },
    getPrimary() {
      let primary = this.data.find(field => field.id === this.config.primary);

      let path = [primary.name];

      while (primary.parent_id) {
        primary = this.data.find(field => field.id === primary.parent_id);
        if (primary) {
          path.splice(0, 0, primary.name);
        }
      }
      return path.join(".");
    },
    isAllowedAsContentIdentifier(data) {
      return !this.findParentCollection(data);
    },
    setContentIdentifier() {
      this.config.contentIdentifier = this.selectedNode.data.id;
      this.configFormValues.contentIdentifier = true;
    },
    changeContentIdentifier(data) {
      if (this.nodeIsContentIdentifier(data)) {
        this.config.contentIdentifier = "";
        return;
      }
      this.setContentIdentifier(data);
    },
    nodeIsContentIdentifier(nodeData) {
      return nodeData.id === this.config.contentIdentifier;
    },
    cloneField() {
      let clone = cloneDeep(this.selectedNode),
        id = generateHash();
      clone.isSelected = false;
      clone.data.id = id;
      clone.data.name += "-clone";
      clone.data.label += " - clone";
      clone.data.original_name = null;

      this.cloneChildren(clone.children, "clone", id);

      this.data.push(clone.data);
      if (this.contextMenu.node.path.length === 1) {
        this.nodes.push(clone);
        return;
      }
      let parent = this.nodes[this.contextMenu.node.path[0]];
      for (let i = 1; i < this.contextMenu.node.path.length - 1; i++) {
        parent = parent.children[this.contextMenu.node.path[i]];
      }
      if (parent.children === undefined) parent.children = [];
      parent.isExpanded = true;
      parent.children.splice(this.contextMenu.node.ind + 1, 0, clone);
      this.validateFields();
    },
    cloneChildren(children, cloneText, id) {
      if (!children) return;
      children.forEach(child => {
        let childId = generateHash();

        child.isSelected = false;
        child.data.id = childId;
        child.data.parent_id = id;
        child.data.original_name = null;
        this.data.push(child.data);

        this.cloneChildren(child.children, cloneText, childId);
      });
    },
    copyField() {
      this.contextMenu.toCopy = cloneDeep(this.selectedNode);
    },
    pasteField() {
      let parent = this.selectedNode;
      if (this.contextMenu.node.isLeaf) {
        parent = this.nodes[this.contextMenu.node.path[0]];
        for (let i = 1; i < this.contextMenu.node.path.length - 1; i++) {
          parent = parent.children[this.contextMenu.node.path[i]];
        }
      }
      const copy = cloneDeep(this.contextMenu.toCopy),
        id = generateHash();
      copy.isSelected = false;
      copy.data.id = id;
      copy.data.name += "-copy";
      copy.data.label += " - copy";
      copy.data.parent_id = parent.data.id;
      copy.data.original_name = null;

      this.cloneChildren(copy.children, "copy", id);
      this.data.push(copy.data);
      if (parent.children === undefined) parent.children = [];
      parent.isExpanded = true;
      parent.children.splice(this.contextMenu.node.ind + 1, 0, copy);
      this.validateFields();
    },
    validateFields() {
      this.config.invalidFields = [];

      for (const data of this.data) {
        if (this.config.invalidFields.includes(data.id)) continue;

        if (!data.name || data.name.trim().length === 0) {
          this.config.invalidFields.push(data.id);
          continue;
        }

        const fields = this.data.filter(d => {
          return (
            d.id !== data.id &&
            d.parent_id === data.parent_id &&
            d.name === data.name
          );
        });

        if (fields.length > 0) {
          fields.push(data);
          this.setFieldsInvalid(fields);
        }
      }
    },
    setFieldsInvalid(fields) {
      this.config.invalidFields.push(...fields.map(f => f.id));
    },
    getInvalidTooltipText(data) {
      if (data.name.trim().length === 0) {
        return this.$t("validation.required.name");
      }
      return this.$t("validation.unique.name");
    },
    dataTypeChanged(value) {
      let config = this.dataFields.find(d => d.name === value).config;
      if (config === undefined) config = [];
      this.selectedNode.data.config = cloneDeep(config);
    },
    validate() {
      if (this.data.length === 0) {
        this.showToast(this.$t("dataStructures.invalidNoFields"), "warning");
        return false;
      }

      if (!this.checkPrimary()) {
        this.showToast(this.$t("dataStructures.invalidNoPrimary"), "warning");
        return false;
      }

      if (this.config.invalidFields.length > 0) {
        this.showToast(
          this.$t("dataStructures.invalidInvalidFields"),
          "warning"
        );
        return false;
      }
      return true;
    },
    showToast(title, icon = "success") {
      this.$toast.fire({
        icon: icon,
        title: title
      });
    },
    setOrderIndex(nodes, index) {
      nodes.forEach(node => {
        let data = this.data.find(d => node.data.id === d.id);
        this.$set(data, "order_index", index.value++);
        if (node.children !== undefined && node.children !== null) {
          this.setOrderIndex(node.children, index);
        }
      });
    },
    async save() {
      if (!this.validate()) {
        return;
      }

      addEventToLoadingQueue({ key: "saveDataStructure" });

      this.setOrderIndex(this.nodes[0].children, { value: 0 });
      let fields = [];
      this.data.forEach(d => {
        const field = {
          id: d.id,
          parent_id: d.parent_id ?? null,
          show_in_view: d.show_in_view ?? false,
          skip_on_dirty_check: d.skip_on_dirty_check ?? false,
          order_index: d.order_index,
          name: d.name,
          original_name: d.original_name,
          label: d.label,
          description: d.description,
          validation_notice: d.validation_notice,
          type: d.type,
          index: d.index ?? false,
          unique: d.unique ?? false,
          nullable: d.nullable ?? false,
          regex: d.regex || null,
          config: d.config
        };
        fields.push(field);
      });

      let data = {
        label: this.dataStructure.label,
        classname: this.dataStructure.classname,
        tablename: this.dataStructure.tablename,
        primary: this.getPrimary(),
        fields: fields,
        queries: this.dataStructure.queries,
        version: this.dataStructure.version,
        config: this.dataStructure.config ?? [],
        is_locked: this.dataStructure.is_locked ?? false,
        is_published: this.dataStructure.is_published ?? false,
        projects: this.dataStructure.projects,
        work_note: this.dataStructure.work_note
      };
      if (typeof this.dataStructure.parent_id !== "undefined") {
        data.parent_id = this.dataStructure.parent_id;
      }
      if (typeof this.dataStructure.config.checkStructure === "string") {
        data.config.checkStructure = JSON.parse(data.config.checkStructure);
      }

      const { addWorkNote } = useWorkNote();
      const { data: updateData, success } = await addWorkNote(data);
      if (!success) {
        removeEventFromLoadingQueue({ key: "saveDataStructure" });
        return;
      }

      if (this.config.update) {
        this.updateDataStructure(this.dataStructure.id, updateData);
        return;
      }
      this.storeDataStructure(updateData);
    },
    updateDataStructure(id, data) {
      addEventToLoadingQueue({ key: "saveDataStructure" });
      DataStructures.update(id, data)
        .then(response => {
          if (response.data.success === false) {
            let error = {
              response: response
            };
            this.catchUpdateError(error);
          } else {
            this.showToast(this.$t("dataStructures.storeSuccessTitle"));
            if (!this.dataStructure?.work_note) {
              this.dataStructure.work_note = {};
            }
            this.dataStructure.work_note.text = response.data.work_note?.text;
          }
        })
        .catch(error => {
          this.catchUpdateError(error);
        })
        .finally(() => {
          removeEventFromLoadingQueue({ key: "saveDataStructure" });
        });
    },
    catchUpdateError(error) {
      if (error.response?.data?.success === false) {
        this.showToast(error.response.data.error, "error");
      } else if (error.response && error.response.status === 422) {
        Object.keys(error.response.errors).forEach(entry => {
          error.response.errors[entry].forEach(entryError => {
            const translationsKey =
              "validation.dataStructure." + entry + "." + entryError;
            this.showToast(this.$i18n.t(translationsKey), "error");
          });
        });
      } else {
        this.showToast(error, "error");
      }
    },
    storeDataStructure(data) {
      addEventToLoadingQueue({ key: "saveDataStructure" });
      DataStructures.store(data)
        .then(response => {
          this.dataStructure = response.data;
          this.dataStructure.projects = this.dataStructure.projects.map(
            project => project.id
          );
          this.dataStructure.tablename = this.dataStructure.raw_tablename;
          if (!this.dataStructure?.work_note) {
            this.dataStructure.work_note = {};
          }
          this.dataStructure.work_note.text = response.data.work_note?.text;
          this.config.update = true;
          this.showToast(this.$t("dataStructures.storeSuccessTitle"));

          this.$router.push({
            name: "projectDataStructuresEditor",
            params: { id: this.dataStructure.id }
          });
        })
        .catch(error => {
          if (error.response && error.response.status === 422) {
            Object.keys(error.response.errors).forEach(entry => {
              error.response.errors[entry].forEach(entryError => {
                const translationsKey =
                  "validation.dataStructure." + entry + "." + entryError;
                this.showToast(this.$i18n.t(translationsKey), "error");
              });
            });
          } else {
            this.showToast(error, "error");
          }
        })
        .finally(() => {
          removeEventFromLoadingQueue({ key: "saveDataStructure" });
        });
    },
    back(event) {
      if (event && (event.ctrlKey || event.metaKey)) {
        this.backNewTab();
        return;
      }

      this.$router.push({ name: "projectDataStructures" });
    },
    backNewTab() {
      const route = this.$router.resolve({
        name: "projectDataStructures"
      });
      window.open(route.href, "_blank");
    },
    getCircleClass(node, attribute) {
      let string = "text-";
      if (!node.isSelected) {
        string += "light-";
      }
      string += node.data[attribute] ? "success" : "danger";
      return string;
    },
    onShowVersions() {
      this.showVersionsModal = true;
    }
  }
};
</script>

<style scoped lang="scss">
.sl-vue-tree {
  > :deep(.sl-vue-tree-nodes-list) {
    .sl-vue-tree-drag-info {
      background-color: white;
      opacity: 0.8;
      border-radius: 5px;
    }

    .sl-vue-tree-selected {
      > .sl-vue-tree-node-item {
        background-color: lightgray;
      }
    }

    > .sl-vue-tree-node {
      border-bottom: none;

      .sl-vue-tree-node-item {
        min-height: 25px;
        padding-top: 4px;
        padding-bottom: 4px;

        &.sl-vue-tree-cursor-inside {
          outline: none;
        }

        .sl-vue-tree-title,
        .sl-vue-tree-sidebar {
          margin-bottom: auto;
          margin-top: auto;
        }
      }
    }
  }
}

:deep(.sl-vue-tree-node) {
  &:not(:last-child) {
    border-bottom: 1px solid #ebedf3;
  }
}

.context-menu-position {
  top: v-bind("contextMenu.top");
  left: v-bind("contextMenu.left");
}

.icon-primary {
  color: rgb(255, 225, 100);
}

.icon-error {
  color: rgb(255, 100, 100);
}

.min-width-20 {
  min-width: 20px;
}
</style>
