<template>
  <div>
    <div class="d-flex justify-content-between align-items-center w-100 mb-5">
      <h5 class="mb-0">{{ $t("mapping.transformers") }}</h5>
      <button
        v-if="!isCollectionField || hasConnections"
        class="btn btn-primary float-right"
        @click="addItem"
      >
        <i class="fal fa-plus" /> {{ $t("mapping.addTransformer") }}
      </button>
      <span v-else class="switch">
        <span class="mr-3">{{ $t("mapping.staticCollection") }}</span>
        <label>
          <input
            v-model="staticCollection"
            type="checkbox"
            :disabled="staticSwitchDisabled"
            @change="handleStaticCollection"
          />
          <span></span>
        </label>
      </span>
    </div>
    <div id="transformer-timeline" class="timeline timeline-3">
      <div class="timeline-items">
        <TimelineItemInput />
        <draggable
          :key="draggableKey"
          v-model="transformersList"
          group="transformers"
          animation="200"
          handle=".timeline-media"
          @start="drag = true"
          @end="dragEnd"
        >
          <TimelineItemConfiguration
            v-for="(transformer, i) in transformersList"
            :key="i"
            :class="{
              'last-item': i === transformersList.length - 1
            }"
            :transformer-item="transformer"
            :is-active="transformer.id === selectedTransformerId"
            :form-key="draggableKey"
            @edit="editItem"
            @remove="removeItem"
            @clear="clearItem"
          />
        </draggable>
        <TimelineItemOutput />
      </div>
    </div>
  </div>
</template>

<script>
import TimelineItemConfiguration from "@/components/Mappings/Transformers/Structure/TimelineItemConfiguration.vue";
import TimelineItemInput from "@/components/Mappings/Transformers/Structure/TimelineItemInput.vue";
import TimelineItemOutput from "@/components/Mappings/Transformers/Structure/TimelineItemOutput.vue";
import { mapActions, mapGetters } from "vuex";
import {
  ADD_TRANSFORMER,
  REMOVE_TRANSFORMER,
  SET_SELECTED_TRANSFORMER_ID,
  SET_STATIC,
  UPDATE_TRANSFORMERS
} from "@/core/services/store/mappingTransformer.module";
import { generateHash } from "@/components/Tools/helperFunctions";
import {
  createCollectionFilterTransformer,
  createCollectionTransformer
} from "@/components/Mappings/helpers";

export default {
  components: {
    TimelineItemConfiguration,
    TimelineItemInput,
    TimelineItemOutput
  },
  props: {},
  data() {
    return {
      drag: false,
      draggableKey: 1,
      staticSwitchDisabled: false
    };
  },
  computed: {
    ...mapGetters("mapping", [
      "mapping",
      "findSourceField",
      "findField",
      "collectionTransformerId"
    ]),
    ...mapGetters("mappingTransformer", [
      "fieldId",
      "fieldStructure",
      "connections",
      "selectedTransformer",
      "selectedTransformerId",
      "transformers",
      "isStatic"
    ]),
    transformersList: {
      get: function () {
        return this.transformers.filter(t => t.position >= 0);
      },
      set: function (list) {
        let hidden = this.transformers.filter(t => t.position < 0);
        list.forEach((t, index) => (t.position = index));
        this[UPDATE_TRANSFORMERS](hidden.concat(list));
      }
    },
    isCollectionField: function () {
      return this.findField(this.fieldId).type === "collection";
    },
    hasCollectionTransformers: function () {
      return !!this.transformers.filter(t => t.position < 0).length;
    },
    hasConnections: function () {
      return !!this.connections.length;
    },
    staticCollection: {
      get: function () {
        return this.isStatic;
      },
      set: function (value) {
        this[SET_STATIC](!!value);
      }
    }
  },
  watch: {
    transformersList: {
      deep: true,
      handler: function () {
        this.draggableKey++;
      }
    }
  },
  mounted() {
    if (
      this.isCollectionField &&
      !this.hasConnections &&
      this.hasCollectionTransformers
    ) {
      this.staticCollection = true;
    }
  },
  methods: {
    ...mapActions("mappingTransformer", [
      SET_SELECTED_TRANSFORMER_ID,
      ADD_TRANSFORMER,
      REMOVE_TRANSFORMER,
      UPDATE_TRANSFORMERS,
      SET_STATIC
    ]),
    addItem() {
      let transformer = {
        id: generateHash(),
        mapping_id: this.mapping.id,
        transformer_id: undefined,
        target_field_id: this.fieldId,
        config: {
          input: [],
          output: ""
        },
        is_source_transformer: !!this.findSourceField(this.fieldId),
        conditions: {
          type: "group",
          operator: "and",
          children: [
            {
              type: "condition",
              field: "",
              operator: "",
              value: "",
              valid: false
            }
          ]
        },
        label: "",
        description: "",
        position: this.transformers.length
      };
      this[ADD_TRANSFORMER](transformer);
    },
    removeItem(id) {
      this[REMOVE_TRANSFORMER](id);
    },
    clearItem(id) {
      this.removeItem(id);
      this.addItem();
    },
    editItem(id) {
      this[SET_SELECTED_TRANSFORMER_ID](id);
    },
    copy(variable) {
      navigator.clipboard.writeText(variable);
      this.$toast.fire({
        icon: "info",
        title: this.$t("general.copied")
      });
    },
    dragEnd() {
      this.drag = false;
      this.draggableKey++;
    },
    handleStaticCollection() {
      if (!this.staticCollection) {
        this[SET_SELECTED_TRANSFORMER_ID]();
        this.removeCollectionTransformers();
      } else {
        this.addCollectionTransformers();
      }
    },
    addCollectionTransformers() {
      this.staticSwitchDisabled = true;
      this[ADD_TRANSFORMER](
        createCollectionTransformer(this.mapping.id, this.fieldId, true)
      );
      this[ADD_TRANSFORMER](
        createCollectionFilterTransformer(this.mapping.id, this.fieldId)
      );
      this.staticSwitchDisabled = false;
    },
    removeCollectionTransformers() {
      this.$swal
        .fire({
          icon: "warning",
          title: this.$t("mapping.staticCollectionTitle"),
          text: this.$t("mapping.staticCollectionText"),
          confirmButtonText: this.$t("general.delete"),
          confirmButtonColor: "var(--primary)",
          showCancelButton: true,
          cancelButtonText: this.$t("general.cancel"),
          cancelButtonColor: "var(--secondary)",
          reverseButtons: true
        })
        .then(result => {
          if (result.isConfirmed) {
            this.removeCollectionTransformersAction();
          } else {
            this.staticCollection = true;
          }
        });
    },
    removeCollectionTransformersAction() {
      this.staticSwitchDisabled = true;
      this[UPDATE_TRANSFORMERS](this.transformers.filter(t => t.position >= 0));
      this.staticSwitchDisabled = false;
    }
  }
};
</script>

<style lang="scss">
.flip-list-move {
  transition: transform 0.5s;
}
.no-move {
  transition: transform 0s;
}
input.form-control[readonly] {
  background-color: #f3f6f9;
}
</style>
