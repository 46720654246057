<template>
  <div v-if="ready">
    <div v-if="connections.length">
      <FormHelper
        v-if="1"
        ref="form"
        v-model="formValues"
        class="pb-5 pl-3"
        :form="form"
        :config="formConfig"
        @input="onFormAction"
      />
    </div>
    <div v-else class="text-muted">
      {{ $t("mapping.noInputConnections") }}
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { UPDATE_TRANSFORMERS } from "@/core/services/store/mappingTransformer.module";
import FormHelper from "@/components/Tools/FormHelper/FormHelper.vue";
import store from "@/core/services/store";
import { SET_VARIABLES } from "@/core/services/store/variables_v1.module";

export default {
  components: { FormHelper },
  props: {
    step: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      filter: [],
      fields: [],
      transformer: [],
      ready: false,
      conditionKey: 1,
      formValues: {},
      form: [],
      formConfig: {
        labelStacked: true,
        snippetPrefix: "mapping",
        customVariables: [
          "inputVariables",
          "outputVariables",
          "sourceVariables"
        ],
        enableTypecast: true
      }
    };
  },
  computed: {
    ...mapGetters("mapping", {
      sourceFields: "sourceFields",
      library: "transformers",
      collectionTransformerId: "collectionTransformerId"
    }),
    ...mapGetters("mappingTransformer", ["transformers", "connections"]),
    inputCollections: function () {
      let collectionTransformer = this.transformers.find(
        t =>
          t.transformer_id === this.collectionTransformerId("Collection") &&
          t.position === -2
      );
      return collectionTransformer.config.collection;
    },
    notFilterTransformers: function () {
      return this.transformers.filter(
        t =>
          !(
            t.transformer_id ===
              this.collectionTransformerId("CollectionFilter") &&
            t.position === -1
          )
      );
    },
    sourceVariables() {
      let fields = this.$store.getters["mapping/mapping"].source.fields.filter(
        item => {
          let valid = false;

          for (const input of this.inputCollections) {
            if (item.full_name.startsWith(input.replace("source.", "")))
              valid = true;
          }

          return valid;
        }
      );
      return fields.map(entry => "source." + entry.full_name);
    },
    condition() {
      let mappingFieldTransformer = this.$store.getters[
        "mapping/mappingFieldTransformers"
      ].filter(c => c.id === this.filter.id);
      let defaultCondition = {
        type: "group",
        operator: "and",
        children: [
          {
            type: "condition",
            field: "",
            operator: "",
            value: "",
            valid: true
          }
        ]
      };

      return mappingFieldTransformer[0].config.filter !== undefined &&
        mappingFieldTransformer[0].config.filter.children[0].valid !== undefined
        ? mappingFieldTransformer[0].config.filter
        : defaultCondition;
    }
  },
  watch: {
    filter: {
      deep: true,
      handler: function () {
        if (!this.ready) return;
        this.updateStore();
      }
    }
  },
  mounted() {
    this.setFilter();
    this.setFields();
    this.setForm();
    this.$nextTick().then(() => (this.ready = true));
    setTimeout(() => this.conditionKey++, 100);
  },
  methods: {
    ...mapActions("mappingTransformer", [UPDATE_TRANSFORMERS]),
    setFilter() {
      this.filter = this.transformers.find(
        t =>
          t.transformer_id ===
            this.collectionTransformerId("CollectionFilter") &&
          t.position === -1
      );
    },
    setFields() {
      this.inputCollections.forEach(fullName => {
        let fields = this.sourceFields.map(f => ({ ...f }));
        let filterName = fullName.split(".");
        let prefix = filterName.shift();
        filterName = filterName.join(".");
        fields = fields.filter(
          f => f.full_name.startsWith(filterName) && f.full_name !== filterName
        );
        fields = fields.map(f => ({
          ...f,
          full_name: prefix + "." + f.full_name
        }));
        this.fields.push(...fields);
      });
    },
    setForm() {
      // get config for transformer
      const transformer = store.getters["mapping/transformerById"](
        this.filter.transformer_id
      );

      // set condition form data w/ variables
      // set transformer & variables
      this.setTransformer();
      this.setConditionFormData();
      this.setConditionVariables();

      // add config to form for formHelper
      Object.keys(transformer.config)
        .filter(key => !["input", "output", "filter"].includes(key))
        .forEach(key => {
          let config = Object.assign({}, transformer.config[key]);
          config.name = key;
          config.label = key;
          config.required = false;

          if (key === "elementIndex") config.default = 0;

          this.form.push(config);

          this.formValues[key] = this.filter.config[key];
        });
    },
    setConditionFormData() {
      this.formValues["filter"] = this.condition;
      this.form.push({
        type: "condition",
        name: "filter",
        enableVariables: true,
        label: this.$t("mapping.filterMergedCollections"),
        validations: {
          required: false
        }
      });
    },
    setConditionVariables() {
      let variableSets = [
        {
          name: "inputVariables",
          variables: this.transformer.config.input
        },
        {
          name: "outputVariables",
          variables: this.filter.config.input
        },
        {
          name: "sourceVariables",
          variables: this.sourceVariables
        }
      ];

      variableSets.forEach(variable => {
        this.setVariables(variable.name, variable.variables);
      });
    },
    setVariables(name, variables) {
      const variableSet = {
        name: name,
        variables: []
      };

      if (name === "sourceVariables") {
        let parentToSearch = variableSet.variables;
        let sourceVariables = [...variables].sort().map(v => v.split(".*."));

        for (const variable of sourceVariables) {
          // Get the prefix of the variable
          let prefix = variable.slice(0, 1)[0];
          // Get the child variables
          let rest = variable.slice(1);
          // set the default parent
          let parent = parentToSearch.find(entry => entry.text === prefix);

          // if there is no parent, set the variable
          if (!parent) {
            variableSet.variables.push({
              text: prefix,
              value: {}
            });

            continue;
          }

          // get the new parent
          parent = parentToSearch.find(entry => entry.text === prefix);

          // loop through the remaining variables and assign them to the parent
          for (let singleVariable of rest) {
            let text = "*." + singleVariable;

            // if the variable already exists as a child variable, we reassign the parent and skip this iteration
            if (parent.value[text]) {
              parent = parent.value[text];
              continue;
            }

            // reassign the parent
            parent = parent.value[text] = {
              text: text,
              value: {}
            };
          }
        }
      } else {
        variables.forEach(input => {
          variableSet.variables.push({
            text: input,
            value: input
          });
        });
      }

      this.$store.dispatch("variables/" + SET_VARIABLES, variableSet);
    },
    setTransformer() {
      this.transformer = Object.assign(
        {},
        this.transformers.find(
          t =>
            t.transformer_id === this.collectionTransformerId("Collection") &&
            t.position === -2
        )
      );
    },
    onFormAction() {
      Object.keys(this.formValues).forEach(key => {
        this.filter.config[key] = this.formValues[key];
      });
    },
    updateStore() {
      let transformers = [this.filter, ...this.notFilterTransformers];
      this[UPDATE_TRANSFORMERS](transformers);
    }
  }
};
</script>
