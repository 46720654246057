import { render, staticRenderFns } from "./ContextMenu.vue?vue&type=template&id=06b51c42&scoped=true"
import script from "./ContextMenu.vue?vue&type=script&setup=true&lang=js"
export * from "./ContextMenu.vue?vue&type=script&setup=true&lang=js"
import style0 from "./ContextMenu.vue?vue&type=style&index=0&id=06b51c42&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "06b51c42",
  null
  
)

export default component.exports