import ApiService from "@/core/services/api.service";
import { BaseService } from "@/components/Tools/baseService";

let url = process.env.VUE_APP_API_ADMIN;

class Mapping extends BaseService {
  get(id) {
    return ApiService.get(url, "mappings/" + id);
  }
  getAll(params, filter = []) {
    let filterParams = this.getParams(filter, params);
    let options = new URLSearchParams(params).toString();
    let headers = {};

    return ApiService.get(url, "mappings?" + options + filterParams, headers);
  }
  update(id, data) {
    return ApiService.put(url + "/mappings/" + id, data);
  }
  store(data) {
    return ApiService.post(url + "/mappings", data);
  }
  delete(id) {
    return ApiService.delete(url + "/mappings/" + id);
  }
  map(mapping, dataStructureDataId) {
    const computedMapping = { ...mapping };
    delete computedMapping["mappingFields"];

    const data = {
      data_structure_data_id: dataStructureDataId,
      mapping: computedMapping
    };
    return ApiService.post(url + "/mappings/test", data);
  }
  transformers() {
    return ApiService.get(url, "mappings/transformers");
  }
  // Resolve/unresolve the relation, depending on the route given
  handleRelation(id, route) {
    return ApiService.post(url + "/mappingFields/" + id + "/" + route);
  }
  exportCsv(params) {
    return ApiService.post(url + "/mappings/export_csv", params);
  }
}
export default new Mapping();
