<template>
  <v-stepper v-model="stepper" class="elevation-0">
    <v-stepper-header class="elevation-0 border-bottom px-8">
      <template v-for="(step, stepIndex) in steps">
        <v-stepper-step
          :key="`${stepIndex}-step`"
          :step="stepIndex + 1"
          :complete="stepper > stepIndex + 1"
        >
          {{ step.label }}
        </v-stepper-step>
        <v-divider
          v-if="stepIndex < steps.length - 1"
          :key="`${stepIndex}-divider`"
        />
      </template>
    </v-stepper-header>

    <v-stepper-items>
      <v-stepper-content
        v-for="(step, stepIndex) in steps"
        :key="stepIndex"
        :step="stepIndex + 1"
      >
        <WizardCollections v-if="stepIndex === 0" ref="step-2" />

        <WizardFilterPost
          v-if="stepIndex === 1"
          ref="step-3"
          :key="renderFilterKey"
        />

        <div class="d-flex justify-content-between border-top pt-5">
          <button
            v-if="stepper > 1"
            class="btn btn-light-primary font-weight-bold text-uppercase px-5 py-4"
            @click="previousStep"
          >
            {{ $t("general.previousStep") }}
          </button>
          <button
            v-if="stepper > 1"
            class="btn btn-primary font-weight-bold text-uppercase px-5 py-4"
            @click="finish"
          >
            {{ $t("general.done") }}
          </button>
          <button
            v-if="stepper < 2"
            class="btn btn-primary font-weight-bold text-uppercase px-5 py-4 ml-auto"
            @click="nextStep"
          >
            <span>{{ $t("general.nextStep") }}</span>
          </button>
        </div>
      </v-stepper-content>
    </v-stepper-items>
  </v-stepper>
</template>

<script>
// Steps
import WizardFilterPost from "@/components/Mappings/Transformers/Structure/CollectionsConfiguration/WizardFilterPost.vue";
import WizardCollections from "@/components/Mappings/Transformers/Structure/CollectionsConfiguration/WizardCollections.vue";

export default {
  components: { WizardCollections, WizardFilterPost },
  props: {},
  data() {
    return {
      stepper: 1,
      steps: [
        {
          label: this.$t("mapping.transformersWizardCollections"),
          icon: "fal fa-blender"
        },
        {
          label: this.$t("mapping.transformersWizardPostFilter"),
          icon: "fal fa-filter"
        }
      ],
      renderFilterKey: 1
    };
  },
  computed: {},
  mounted() {},
  methods: {
    finish() {
      this.$emit("hide");
    },
    previousStep() {
      this.stepper--;
    },
    nextStep() {
      this.stepper++;
      this.renderFilterKey++;
    }
  }
};
</script>

<style lang="scss"></style>
