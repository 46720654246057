<template>
  <div>
    <MappingTool v-if="showMapper" />
    <Create v-else-if="$route.name === 'projectMappingsMappingsCreate'" />
    <Details v-else-if="$route.name === 'projectMappingsMappingsDetails'" />
    <Table v-else />
  </div>
</template>

<script>
import Table from "@/components/Mappings/Table.vue";
import MappingTool from "@/components/Mappings/MappingTool.vue";
import Create from "@/components/Mappings/Create.vue";
import Details from "@/components/Mappings/Details.vue";

export default {
  components: { Create, Details, MappingTool, Table },
  props: [],
  data() {
    return {
      key: 1,
      mapperRoutes: [
        "projectMappingsMappingMapper",
        "projectMappingsMappingDebug"
      ]
    };
  },
  computed: {
    showMapper: function () {
      return this.mapperRoutes.includes(this.$route.name);
    }
  }
};
</script>
