import ApiService from "@/core/services/api.service";

const url = process.env.VUE_APP_API_ADMIN;

class Backup {
  show(classname, id) {
    return ApiService.get(url + "/backup/" + classname + "/" + id);
  }

  showBackup(classname, id, filename) {
    return ApiService.post(url + "/backup/" + classname + "/" + id, {
      path: filename
    });
  }
}

export default new Backup();
