<template>
  <div class="card card-custom">
    <div class="card-header pa-4">
      <div class="card-title">
        <h3 class="card-label">{{ $t("dataStructures.toolbox") }}</h3>
      </div>
    </div>

    <div class="card-body pa-0">
      <v-expansion-panels accordion>
        <v-expansion-panel>
          <v-expansion-panel-header>
            {{ $t("dataStructures.structureFields") }}
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <div
              v-for="(item, key) in structureFields"
              :key="key"
              class="toolbox-item"
              draggable="true"
              @dragstart="dragStart(item)"
            >
              <div class="py-1">
                <i :class="fieldTypeIcons[item.icon]" class="toolbox-icon" />
                <span class="ml-1">{{ item.name }}</span>
              </div>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header>
            {{ $t("dataStructures.dataFields") }}
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <div
              v-for="(item, key) in dataFields"
              :key="key"
              class="toolbox-item"
              draggable="true"
              @dragstart="dragStart(item)"
            >
              <div class="py-1">
                <i :class="fieldTypeIcons[item.type]" class="toolbox-icon" />
                <span class="ml-1">{{ item.label }}</span>
              </div>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    structureFields: {
      type: Array,
      default: () => []
    },
    dataFields: {
      type: Array,
      default: () => []
    },
    fieldTypeIcons: {
      type: Object,
      default: () => {}
    }
  },
  methods: {
    dragStart(item) {
      this.$emit("drag-start", item);
    }
  }
};
</script>

<style scoped lang="scss">
.toolbox-item:hover {
  background-color: whitesmoke;
}

.toolbox-icon {
  width: 20px;
}

.v-expansion-panel::before {
  box-shadow: none;
}
</style>
