<template>
  <div>
    <div v-if="!node">
      <span>{{ $t("workflowDesigner.selectWidget") }}</span>
    </div>
    <div v-else>
      <FormHelper
        v-model="values"
        :form="form"
        :config="formConfig"
        @change="onChange"
        @action="onAction"
      />
      <div v-for="(field, i) in jsonDataFields" :key="i" class="form-group">
        <label v-if="jsonDataFields.length > 1" class="col-form-label">{{
          field.label ?? field.name
        }}</label>
        <vue-json-pretty
          :data="field.data"
          :show-length="true"
          :deep="2"
          highlight-mouseover-node
          class="mt-1"
        />
      </div>
    </div>
  </div>
</template>

<script>
import FormHelper from "@/components/Tools/FormHelper/FormHelper.vue";
import VueJsonPretty from "vue-json-pretty";
import { bus } from "@/main";
import _ from "lodash";
import { addAreaPrefix } from "@/components/Workflows/Designer/Canvas/Configuration/functions";

export default {
  components: { FormHelper, VueJsonPretty },
  props: ["node", "configValues", "outputValues", "debugValues"],
  data() {
    return {
      areas: ["authentication", "configuration", "input", "output", "error"],
      formConfig: {
        snippetPrefix: this.snippetPrefix(),
        labelStacked: true
      },
      debouncedUpdate: _.debounce(this.checkResultNameChange, 1000)
    };
  },
  computed: {
    values: {
      get() {
        let values = {};
        this.areas.forEach(area => (values[area] = {}));
        Object.keys(this.node.attrs.data).forEach(area => {
          if (!this.areas.includes(area)) {
            return;
          }
          this.node.attrs.data[area].forEach(field => {
            values[area][field.name] = field.value;
          });
        });
        return values;
      },
      set(values) {
        let config = _.cloneDeep(this.node.attrs.data.output);
        Object.keys(values.output).forEach(key => {
          let field = config.find(f => f.name === key);
          field.value = values.output[key];
        });
        this.node.attrs.data.output = config;
      }
    },
    form: function () {
      let form = this.node?.attrs.data.output ?? [];
      return addAreaPrefix(form, "output");
    },
    jsonDataFields: function () {
      return this.node.attrs.data.output.filter(f => !!f.data) ?? [];
    }
  },
  mounted() {},
  methods: {
    snippetPrefix() {
      return (
        "workflowElements." +
        this.$store.getters.getElementByName(
          this.node.attrs.data.flow_element_name
        ).name
      );
    },
    onInputChanged(field) {
      bus.$emit("outputValueChanged", field, this.node.attrs.data.hash);
    },
    onChange(payload) {
      this.debouncedUpdate(payload);
      this.$emit("change");
    },
    checkResultNameChange(payload) {
      let field = this.node.attrs.data.output.find(
        f => "output." + f.name === payload.name
      );
      // Add index to id for handling multiple output value fields
      let index = this.node.attrs.data.output.indexOf(field);
      if (!field || field.type !== "text") {
        return;
      }
      bus.$emit("update-custom-variable-value", {
        id: this.node.attrs.data.hash + "-" + index,
        field: field,
        prefix: "output"
      });
    },
    onAction(actionName) {
      let field = this.form.find(field => field.action === actionName);
      bus.$emit("fireAction", field, false);
    }
  }
};
</script>

<style scoped>
.output-text {
  font-size: 1.15rem;
}
</style>
