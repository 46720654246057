<template>
  <div>
    <div v-if="transformer">
      <FormHelper
        ref="form"
        v-model="formTransformer"
        :form="form"
        :config="config"
        :no-data-text="$t('mapping.noTransformersAvailable')"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { UPDATE_TRANSFORMERS } from "@/core/services/store/mappingTransformer.module";
import FormHelper from "@/components/Tools/FormHelper/FormHelper";
import output from "../../../../Workflows/Designer/Canvas/Configuration/Output.vue";

export default {
  components: { FormHelper },
  props: {
    transformer: {
      type: Object,
      default: () => {}
    },
    transformerPosition: {
      type: Number,
      default: () => {}
    }
  },
  data() {
    return {
      formTransformer: this.transformer,
      form: [
        {
          label: this.$t("mapping.transformerLabel"),
          name: "label",
          type: "text",
          strict: false,
          default: this.transformer.label,
          disableReturnType: false,
          copy: false,
          enableVariables: false,
          validations: {
            required: false,
            minLength: 1,
            alphaNumSpace: true
          }
        },
        {
          type: "multiselect",
          name: "config.input",
          label: this.$t("mapping.transformerInputVars"),
          placeholder: "select",
          clearable: true,
          default: this.transformer.config.input,
          options: []
        },
        {
          label: this.$t("mapping.transformerOutput"),
          name: "config.output",
          type: "text",
          strict: false,
          disableReturnType: true,
          copy: false,
          prepend: "{{",
          append: "}}",
          enableVariables: false,
          validations: {
            required: false,
            minLength: 1,
            alphaNumSpace: false
          }
        }
      ],
      config: {
        labelStacked: true,
        snippetPrefix: "formHelper.test"
      }
    };
  },
  computed: {
    output() {
      return output;
    },
    selectedOptions() {
      return this.formTransformer.config.input;
    },
    transformerLabel() {
      return this.formTransformer.label;
    },
    ...mapGetters("mappingTransformer", [
      "fieldId",
      "vars",
      "transformers",
      "outputVars"
    ]),
    ...mapGetters("mapping", {
      library: "transformers",
      findField: "findField"
    }),
    transformerOutput: {
      get: function () {
        if (this.transformer.config.output.length) {
          return this.transformer.config.output;
        }

        return "output.output" + (this.transformer.position + 1);
      },
      set: function (newName) {
        let currentOutput = this.transformer.config.output,
          dependencyUpdated = false,
          transformers = [...this.transformers];
        transformers.forEach(t => {
          let index = t.config.input.indexOf(currentOutput);
          if (index === -1) {
            return;
          }
          t.config.input[index] = newName;
          dependencyUpdated = true;
        });
        if (dependencyUpdated) {
          this.$store.dispatch(
            "mappingTransformer/" + UPDATE_TRANSFORMERS,
            transformers
          );
        }
        this.transformer.config.output = "output." + newName;
      }
    },
    inputVarsSelection: function () {
      // Get field and default input vars options
      let field = this.findField(this.fieldId),
        options = this.vars(this.transformer.position);
      // If field type is array do not return source field, only return output values, "currentValue" and "originalValue" as input options
      if (field.type === "array") {
        options = [
          ...options.filter(o => !o.startsWith("source.")),
          ...["currentValue", "originalValue"]
        ];
      }
      return options;
    }
  },
  watch: {
    selectedOptions() {
      this.transformer.config.input = this.selectedOptions;
    },
    transformerLabel() {
      this.transformer.label = this.transformerLabel;
    }
  },
  mounted() {
    this.transformer.config.output = this.transformerOutput;
    this.form[1].options = this.inputVarsSelection;
  }
};
</script>
