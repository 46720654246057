<template>
  <div>
    <div class="d-flex justify-content-between align-items-center mb-5">
      <h5>
        {{ $t("mapping.collectionsConfiguration") }}
      </h5>
      <button
        v-if="!showWizard"
        class="btn btn-secondary"
        @click="wizard(true)"
      >
        <i class="fal fa-gear" />
        {{ $t("mapping.configureCollectionTransformers") }}
      </button>
    </div>
    <div v-if="showWizard">
      <Wizard @hide="wizard(false)" />
    </div>
    <div v-else>
      <h5>{{ $t("mapping.transformersWizardCollections") }}</h5>
      <div class="mb-3">
        {{ $t("mapping.collection") }}:
        {{ collectionTransformerType }}
      </div>
      <div class="separator separator-solid mb-3" />
      <h5>{{ $t("mapping.transformersWizardPostFilter") }}</h5>
      <code v-if="postFilterRendered">{{ postFilterRendered }}</code>
      <div v-else class="text-muted">{{ $t("mapping.noFilters") }}</div>
    </div>
  </div>
</template>

<script>
import Wizard from "@/components/Mappings/Transformers/Structure/CollectionsConfiguration/Wizard.vue";
import { mapGetters } from "vuex";
import { renderConditions } from "@/components/Mappings/helpers";

export default {
  components: { Wizard },
  props: {},
  data() {
    return {
      showWizard: false
    };
  },
  computed: {
    ...mapGetters("mapping", {
      library: "transformers",
      collectionTransformerId: "collectionTransformerId"
    }),
    ...mapGetters("mappingTransformer", ["transformers"]),
    collectionTransformer: function () {
      return this.transformers.find(
        t =>
          t.transformer_id === this.collectionTransformerId("Collection") &&
          t.position === -2
      );
    },
    postFilter: function () {
      return this.transformers.find(
        t =>
          t.transformer_id ===
            this.collectionTransformerId("CollectionFilter") &&
          t.position === -1
      );
    },
    collectionTransformerType: function () {
      let original = this.library.find(
        t => t.id === this.collectionTransformerId("Collection")
      );
      let selection = this.collectionTransformer.config.type;
      if (![1, 2, 3].includes(selection)) {
        return this.$t("mapping.noValue");
      }
      let selectionLabel = original.config.type.options.find(
        o => o.value === selection
      ).label;
      selectionLabel = selectionLabel[this.$store.getters.language];
      return selectionLabel;
    },
    postFilterRendered: function () {
      let condition =
        this.postFilter.config.condition?.type.length !== undefined
          ? this.postFilter.config.condition
          : this.postFilter.config.filter;
      return renderConditions(condition);
    }
  },
  mounted() {},
  methods: {
    wizard(show) {
      this.showWizard = show;
    }
  }
};
</script>
