<template>
  <v-dialog
    ref="transformersModal"
    v-model="show"
    persistent
    content-class="transformers-modal"
  >
    <div class="card card-custom h-auto min-h-100 px-5">
      <div class="card-header">
        <div class="card-title">
          <h5 v-if="field" class="card-label">
            <span>Transformer - </span> {{ field.label }} ({{ field.name }})
          </h5>
        </div>
      </div>
      <!--  Start structure mode  -->
      <div v-if="!isDebug" class="w-100 row py-10">
        <div class="col-6 pr-5">
          <StructureDetails />
        </div>
        <v-divider vertical />
        <div class="col-6 pl-5">
          <CollectionsConfiguration
            v-if="selectedTransformerId === 'collections'"
          />
          <StructureConfiguration
            v-else
            :key="configKey"
            :field-type="parentFieldType"
          />
        </div>
      </div>
      <!--  End structure mode  -->
      <!--  Start debug mode  -->
      <div v-else class="w-100" style="max-width: 800px">
        <DebugDetails />
      </div>
      <div class="card-footer">
        <div class="d-flex align-items-center justify-content-end w-100">
          <button class="btn btn-secondary" @click="close">
            {{ !isDebug ? $t("general.cancel") : $t("general.close") }}
          </button>
          <button v-if="!isDebug" class="btn btn-primary ml-1" @click="save">
            {{ $t("general.takeOn") }}
          </button>
        </div>
      </div>
      <!--  End debug mode  -->
    </div>
  </v-dialog>
</template>

<script>
// Structure components
import StructureDetails from "@/components/Mappings/Transformers/Structure/Details.vue";
import StructureConfiguration from "@/components/Mappings/Transformers/Structure/Configuration/Index.vue";
import CollectionsConfiguration from "@/components/Mappings/Transformers/Structure/CollectionsConfiguration/Index.vue";
// Debug components
import DebugDetails from "@/components/Mappings/Transformers/Debug/Details.vue";
import { mapGetters } from "vuex";

export default {
  components: {
    StructureConfiguration,
    StructureDetails,
    CollectionsConfiguration,
    DebugDetails
  },
  props: {},
  data() {
    return {
      show: true,
      field: {},
      selectedTransformer: undefined,
      configKey: 1,
      parentFieldType: null
    };
  },
  computed: {
    ...mapGetters("mapping", ["findField", "mappingResult"]),
    ...mapGetters("mappingTransformer", ["fieldId", "selectedTransformerId"]),
    isDebug: function () {
      return this.$route.name === "projectMappingsMappingDebug";
    }
  },
  mounted() {
    this.setField();
    this.showHide();
    this.setParentFieldType(this.field);
  },
  methods: {
    close() {
      this.$emit("close");
    },
    save() {
      this.$emit("save");
    },
    showHide() {
      if (this.show) {
        this.$refs.transformersModal.show();
      } else {
        this.$refs.transformersModal.hide();
      }
    },
    setField() {
      this.field = this.findField(this.fieldId);
    },
    setParentFieldType(field) {
      if (field.parent_id && field.type !== "collection") {
        return this.setParentFieldType(this.findField(field.parent_id));
      }

      this.parentFieldType = field.type;
    }
  }
};
</script>

<style lang="scss">
.transformers-modal {
  min-width: 800px;
  max-width: 85%;
  height: 90%;
}
.min-h-100 {
  min-height: 100%;
}
</style>
