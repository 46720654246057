import { generateHash } from "@/components/Tools/helperFunctions";
import store from "@/core/services/store";

export function getTypeIcon(type, showChildren = true) {
  let icon = "";
  switch (type) {
    case "int":
    case "integer":
    case "float":
      icon = "fal fa-sort-numeric-down";
      break;
    case "string":
    case "text":
      icon = "fal fa-edit";
      break;
    case "bool":
      icon = "fal fa-check-square";
      break;
    case "json":
      icon = "fal fa-code";
      break;
    case "date":
      icon = "fal fa-calendar";
      break;
    case "time":
      icon = "fal fa-clock";
      break;
    case "relation":
      icon = "fal fa-link";
      break;
    case "dynamic":
      icon = "fal fa-cubes";
      break;
    case "collection":
      icon = showChildren ? "fal fa-rectangle-history" : "fal fa-rectangle";
      break;
    case "fieldset":
      icon = showChildren ? "fal fa-folder-open" : "fal fa-folder";
      break;
  }
  return icon;
}

export function typeCast(value) {
  let result = value;
  if (
    (value.startsWith('"') && value.endsWith('"')) ||
    (value.startsWith("'") && value.endsWith("'"))
  ) {
    result = String(value);
  } else if (value === "true") {
    result = true;
  } else if (value === "false") {
    result = false;
  } else if (value === "null") {
    result = null;
  } else if (!isNaN(value) && value !== "") {
    result = Number(value);
  }
  return result;
}

export function checkDepending(config, configurations) {
  if (!config.dependsOn) {
    return true;
  }

  for (const item of config.dependsOn) {
    const { name } = item;
    const dependencyConfig = configurations.find(x => x.name === name);

    if (!dependencyConfig) {
      continue;
    }

    const dependencyConfigValue = dependencyConfig.value;

    if (item.values && !item.values.includes(dependencyConfigValue)) {
      config.value = "";
      return false;
    }

    if (item.notValues && item.notValues.includes(dependencyConfigValue)) {
      config.value = "";
      return false;
    }
  }

  return true;
}

export function renderConditions(item, isFirst = true) {
  let text = "";
  if (item.type === "group") {
    let groupText = "";
    item.children.forEach((child, index) => {
      let childText = renderConditions(child, false);
      if (index > 0 && groupText && childText) {
        groupText +=
          item.operator === "and"
            ? " && "
            : item.operator === "or"
            ? " || "
            : "";
      }
      groupText += childText;
    });
    if (groupText.length) {
      text += isFirst ? groupText : `(${groupText})`;
    }
  } else if (
    item.type === "condition" &&
    item.field &&
    item.operator &&
    (String(item.value).length || item.operator.match(/[a-z]/i))
  ) {
    let value = String(item.value);
    if (
      typeof item.value === "string" &&
      !(value.startsWith("{{") && value.endsWith("}}")) &&
      !(value.startsWith('"') && value.endsWith('"')) &&
      !(value.startsWith("'") && value.endsWith("'"))
    ) {
      value = '"' + value + '"';
    }
    text += `${item.field} ${item.operator}${
      item.operator.match(/[a-z]/i) ? "" : ` ${value}`
    }`;
  }
  return text;
}

export function getDefaultCondition() {
  return {
    type: "group",
    operator: "and",
    children: [
      {
        type: "filter",
        field: "",
        operator: "",
        value: "",
        valid: undefined
      }
    ]
  };
}

function getBaseTransformer(mappingId, fieldId) {
  return {
    label: "",
    description: "",
    conditions: {},
    is_source_transformer: 0,
    mapping_id: mappingId,
    target_field_id: fieldId
  };
}

export function createCollectionTransformer(
  mappingId,
  fieldId,
  isStatic = false
) {
  return Object.assign(getBaseTransformer(mappingId, fieldId), {
    id: generateHash(),
    transformer_id:
      store.getters["mapping/collectionTransformerId"]("Collection"),
    config: {
      collection: [],
      input: [],
      output: "collection",
      type: isStatic ? 0 : 3,
      valueCount: 1
    },
    position: -2
  });
}

export function createCollectionFilterTransformer(mappingId, fieldId) {
  return Object.assign(getBaseTransformer(mappingId, fieldId), {
    id: generateHash(),
    transformer_id:
      store.getters["mapping/collectionTransformerId"]("CollectionFilter"),
    config: {
      input: ["output.collection"],
      output: "collection",
      filter: getDefaultCondition()
    },
    position: -1
  });
}

// Returns only the root parent field
export function getRootParent(field, fields) {
  let parent = field;
  while (parent.parent_id) {
    parent = fields.find(f => f.id === parent.parent_id);
  }
  return parent;
}

// Return a list of all parent ids of given field, including its own id
export function getParentIds(field, fields) {
  let parent = field,
    ids = [field.id];
  while (parent.parent_id) {
    parent = fields.find(f => f.id === parent.parent_id);
    ids.push(parent.id);
  }
  return ids;
}

// Returns if field's props match given filter string
export function passesFilter(field, filter) {
  return JSON.stringify([field.label, field.name, field.type])
    .trim()
    .toLowerCase()
    .includes(filter.trim().toLowerCase());
}

// Returns if the given field should be treated as a collection
export function isCollectionField(field) {
  return (
    field.field_type === "collection" ||
    (field.field_type === "relation" &&
      field?.data_structure_field_config.find(el => el.name === "relation_type")
        .value === "collection")
  );
}
