<template>
  <div class="timeline-item">
    <div class="timeline-media">
      <i class="fal fa-arrow-right-to-arc" />
    </div>
    <div class="timeline-content">
      <div class="mb-3">{{ $t("mapping.initialVariables") }}</div>
      <div v-if="inputVars.length">
        <div
          v-for="(variable, i) in inputVars"
          :key="i"
          class="input-group mb-1"
        >
          <div class="input-group-prepend">
            <span
              class="input-group-text cursor-pointer"
              @click="copyVariable(variable)"
            >
              <i class="fal fa-copy" />
            </span>
          </div>
          <input :value="variable" type="text" class="form-control" readonly />
        </div>
      </div>
      <div v-else class="text-muted">
        {{ $t("mapping.noAvailableVariables") }}
      </div>
      <div
        v-if="showCollectionsButton"
        class="text-right mt-3"
        @click="editCollections"
      >
        <span class="mr-1 align-middle">
          <i
            v-if="!collectionTransformersValid"
            class="fal fa-circle-exclamation fa-fade text-danger"
          />
        </span>
        <button class="btn btn-secondary align-middle">
          {{ $t("mapping.collections") }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { copyToClipboard } from "@/components/Tools/helperFunctions";
import { SET_SELECTED_TRANSFORMER_ID } from "@/core/services/store/mappingTransformer.module";
import { validateTransformer } from "@/components/Mappings/validation";

export default {
  components: {},
  props: {},
  data() {
    return {};
  },
  computed: {
    ...mapGetters("mapping", [
      "sourceFields",
      "findTargetField",
      "mappingConnectionsByTargetField",
      "collectionTransformerId"
    ]),
    ...mapGetters("mappingTransformer", [
      "inputVars",
      "transformers",
      "fieldStructure",
      "fieldId",
      "connections"
    ]),
    showCollectionsButton: function () {
      return (
        this.fieldStructure === "target" &&
        this.transformers.find(
          t => t.transformer_id === this.collectionTransformerId("Collection")
        )
      );
    },
    collectionTransformersValid: function () {
      let valid = true;
      this.transformers
        .filter(t => t.position < 0)
        .forEach(t => {
          if (validateTransformer(t) === false) {
            valid = false;
          }
        });
      return valid;
    }
  },
  mounted() {},
  methods: {
    copyVariable: variable => copyToClipboard(variable, "{{", "}}"),
    editCollections() {
      this.$store.dispatch(
        "mappingTransformer/" + SET_SELECTED_TRANSFORMER_ID,
        "collections"
      );
    }
  }
};
</script>
