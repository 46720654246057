<template>
  <div>
    <Header
      :title="$t('mapping.mappings').toString()"
      :items="headerButtons"
      @create-mapping="createMapping"
    />
    <TableHelper
      ref="table"
      :items="mappings"
      :meta="meta"
      :fields="fields"
      :actions="actions"
      :enable-filter="true"
      :filterable="filterable"
      default-order-by="label"
      :exportable="true"
      loading-key="loadMappings"
      @reload-data="loadMappings"
      @open="openMapping"
      @edit="openDetails"
      @showVersions="onShowVersions"
      @delete="deleteMapping"
      @export-csv="exportCsv"
    />

    <BackupModal
      v-model="showVersionsModal"
      :object="selectedMapping"
      classname="Mapping"
    />
  </div>
</template>

<script>
import Mapping from "@/components/Mappings/mapping";
import { mapGetters } from "vuex";
import TableHelper from "@/components/Tools/TableHelper/TableHelper.vue";
import Header from "@/components/Tools/Header/Header.vue";
import {
  addEventToLoadingQueue,
  removeEventFromLoadingQueue
} from "@/composables/useLoadingQueue";
import BackupModal from "@/components/Backup/BackupModal.vue";
import { useCurrentProjectFilter } from "@/composables/useCurrentProjectFilter";

export default {
  components: { BackupModal, Header, TableHelper },
  props: [],
  data() {
    return {
      mappings: [],
      fields: [
        {
          key: "label",
          label: this.$t("table.name"),
          sortable: true
        },
        {
          key: "description",
          label: this.$t("table.description"),
          sortable: true
        },
        {
          key: "source",
          label: this.$t("mapping.dataStructureSource"),
          formatter: source => source.label,
          sortable: true
        },
        {
          key: "target",
          label: this.$t("mapping.dataStructureTarget"),
          formatter: target => target.label,
          sortable: true
        }
      ],
      // Meta info
      meta: {},
      selectedMapping: null,
      showVersionsModal: false,
      // Filterable fields
      filterable: {}
    };
  },
  computed: {
    ...mapGetters("route", ["params"]),
    ...mapGetters("instance", ["instance"]),
    ...mapGetters([
      "selectedProject",
      "selectedPresetVersion",
      "isPresetProject",
      "isDevPresetVersion"
    ]),
    headerButtons: function () {
      const headerButtons = [
        {
          type: "button",
          title: this.$t("mapping.mappingCreate"),
          icon: this.$store.getters["config/icons"].add,
          emit: "create-mapping"
        }
      ];
      return this.isPresetProject || !this.isDevPresetVersion
        ? []
        : headerButtons;
    },
    actions: function () {
      let actions = [
        {
          key: "open",
          icon: this.$store.getters["config/icons"].editor,
          emit: "open",
          tooltip: this.$t("mapping.edit")
        },
        {
          key: "edit",
          icon: this.$store.getters["config/icons"].edit,
          emit: "edit",
          tooltip: this.$t("mapping.configuration")
        },
        {
          key: "versions",
          icon: this.$store.getters["config/icons"].version,
          emit: "showVersions"
        }
      ];
      if (this.isPresetProject || !this.isDevPresetVersion) {
        return actions;
      }
      actions.push({
        key: "delete",
        icon: this.$store.getters["config/icons"].delete,
        emit: "delete",
        tooltip: this.$t("mapping.delete")
      });
      return actions;
    }
  },
  watch: {
    selectedProject: function () {
      this.loadMappings();
    },
    selectedPresetVersion: function () {
      this.loadMappings();
    }
  },
  mounted() {
    this.loadMappings();
  },
  methods: {
    loadMappings(payload = {}) {
      this.mappings = [];

      if (this.instance?.isPresetInstance && !this.selectedPresetVersion) {
        return;
      }

      addEventToLoadingQueue({ key: "loadMappings" });

      let params = { ...this.params(), ...payload };
      const { filter } = useCurrentProjectFilter();

      Mapping.getAll(params, filter.value)
        .then(response => {
          this.mappings = response.data;
          this.meta = response.meta;
          this.filterable = response.meta.filterable;
          removeEventFromLoadingQueue({ key: "loadMappings" });
        })
        .catch(error => {
          this.$error(error);
        });
    },
    createMapping() {
      this.$router.push({ name: "projectMappingsMappingsCreate" });
    },
    openMapping(item) {
      this.$router.push({
        name: "projectMappingsMappingMapper",
        params: { id: item.item.id }
      });
    },
    openDetails(item) {
      this.$router.push({
        name: "projectMappingsMappingsDetails",
        params: { id: item.item.id }
      });
    },
    onShowVersions(data) {
      this.selectedMapping = data.item;
      this.showVersionsModal = true;
    },
    deleteMapping(item) {
      this.$swal
        .fire({
          icon: "warning",
          title: this.$t("mapping.deleteMappingTitle", {
            title: item.item.label
          }),
          text: this.$t("mapping.deleteMappingText"),
          showConfirmButton: true,
          confirmButtonText: this.$t("general.delete"),
          confirmButtonColor: "var(--primary)",
          showCancelButton: true,
          reverseButtons: true,
          cancelButtonText: this.$t("general.cancel")
        })
        .then(result => {
          if (!result.isConfirmed) {
            return;
          }
          addEventToLoadingQueue({ key: "deleteMapping" });
          Mapping.delete(item.item.id)
            .then(() => {
              removeEventFromLoadingQueue({
                key: "deleteMapping",
                type: "success",
                prefix: "mapping",
                name: "mappingDeleted"
              });
              removeEventFromLoadingQueue({ key: "deleteMapping" });
              this.loadMappings();
            })
            .catch(error => {
              this.$error(error);
            });
        });
    },
    exportCsv(params) {
      Mapping.exportCsv(params).catch(error => {
        this.$error(error);
      });
    }
  }
};
</script>
