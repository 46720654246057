<template>
  <div v-if="tabIndex === 1" class="p-3">
    <FormHelper
      ref="form"
      v-model="formCondition"
      :form="form"
      :config="config"
      :no-data-text="$t('mapping.noTransformersAvailable')"
      @change="updateTransformerConditions"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { copyToClipboard } from "@/components/Tools/helperFunctions";
import { SET_VARIABLES } from "@/core/services/store/variables_v1.module";
import FormHelper from "@/components/Tools/FormHelper/FormHelper";
import { UPDATE_TRANSFORMER } from "@/core/services/store/mappingTransformer.module";

export default {
  components: { FormHelper },
  props: {
    transformer: {
      type: Object,
      default: () => {}
    },
    tabIndex: {
      type: Number,
      default: 0
    },
    conditions: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      formCondition: { condition: this.conditions },
      form: [
        {
          type: "condition",
          name: "condition",
          label: this.$t("mapping.transformerConfigurationConditions"),
          validations: {
            required: false
          }
        }
      ],
      config: {
        class: "form-control",
        labelStacked: true,
        snippetPrefix: "formHelper.test",
        enableVariables: true,
        customVariables: [
          "inputVariables",
          "outputVariables",
          "objectVariables"
        ],
        enableTypecast: true
      }
    };
  },
  computed: {
    ...mapGetters("mapping", ["source"]),
    outputVariables() {
      const filtered_data = this.$store.getters[
        "mappingTransformer/transformers"
      ].filter(entry => entry.config.output !== this.transformer.config.output);

      return filtered_data.map(entry => entry.config.output);
    },
    objectVariables() {
      return this.$store.getters["mapping/mapping"].sourceFields.map(
        entry => "object." + entry.full_name
      );
    }
  },
  watch: {
    transformer: {
      handler() {
        // TODO PERFORMANCE PROBLEM
        this.setConditionVariables();
      },
      deep: true
    }
  },
  mounted() {
    this.setConditionVariables();
  },
  methods: {
    ...mapActions("mappingTransformer", [UPDATE_TRANSFORMER]),
    copyVariable(variable) {
      copyToClipboard(variable, "{{", "}}");
    },
    updateTransformerConditions() {
      // TODO recognize operator change and update operators for TimelineItemConfiguration Condition (Line. 66)
      this.transformer.conditions = this.formCondition.condition;
      const payload = {
        id: this.transformer.id,
        transformer: this.transformer
      };
      this[UPDATE_TRANSFORMER](payload);
    },
    setConditionVariables() {
      let variableSets = [
        {
          name: "inputVariables",
          variables: this.transformer.config.input
        },
        {
          name: "outputVariables",
          variables: this.outputVariables
        },
        {
          name: "objectVariables",
          variables: this.objectVariables
        }
      ];

      variableSets.forEach(variable => {
        this.setVariables(variable.name, variable.variables);
      });
    },
    setVariables(name, variables) {
      const variableSet = {
        name: name,
        variables: []
      };
      variables.forEach(input => {
        variableSet.variables.push({
          text: input,
          value: input
        });
      });
      this.$store.dispatch("variables/" + SET_VARIABLES, variableSet);
    }
  }
};
</script>
