import Swal from "sweetalert2";
import { useStore } from "@/core/services/store";
import i18n from "@/core/plugins/vue-i18n";
export const useWorkNote = () => {
  const store = useStore();

  const addWorkNote = async originalData => {
    let data = JSON.parse(JSON.stringify(originalData));
    if (!store.getters["instance/instance"].isPresetInstance) {
      return { data, success: true };
    }
    if (!data.work_note) {
      data.work_note = {
        text: ""
      };
    }
    return await Swal.fire({
      icon: "info",
      title: i18n.t("workNotes.addWorkNote"),
      input: "textarea",
      inputLabel: i18n.t("workNotes.workNote"),
      inputValue: data.work_note.text,
      inputValidator(inputValue) {
        if (!inputValue) {
          return i18n.t("workNotes.validationRequired");
        }
      },
      showCancelButton: true,
      confirmButtonText: i18n.t("general.save"),
      reverseButtons: true,
      confirmButtonColor: "#5b64ee",
      allowOutsideClick: false
    })
      .then(result => {
        if (result.isConfirmed) {
          data.work_note.text = result.value;
          return { data, success: true };
        }
        return { data, success: false };
      })
      .catch(error => {
        this.$error(error);
        return { data, success: false };
      });
  };
  return { addWorkNote };
};
