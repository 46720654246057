import _ from "lodash";
import { bus } from "@/main";

export function addAreaPrefix(fields, area = "") {
  let form = _.cloneDeep(fields);
  return form.map(field => {
    if (field.type !== "action") {
      field.name = area + "." + field.name;
    }
    return field;
  });
}

/**
 * @param fields
 * @param {string} area
 */
export function typeTranslation(fields, area = "") {
  let form = _.cloneDeep(fields);
  form.forEach(field => {
    if (field.type !== "action") {
      field.name = area + "." + field.name;
    }
    if (!field.label) {
      field.label =
        field.type === "action" ? area + "." + field.name : field.name;
    }
    // Check if field validations is set
    if (!field.validations) {
      field.validations = {};
    }
    // Check if required attribute is set
    if (field.required) {
      field.validations.required = true;
    }
    if (field.type === "action") {
      field.buttonLabel = area + "." + field.name;
      field.action = () => {
        bus.$emit("fireAction", field, false);
      };
    }
    // // Modify dependency structure
    // if (field.dependsOn) {
    //   field.dependsOn = adaptDependencies(field.dependsOn);
    // }
    // Find translation
    let translation = translations[field.type];
    if (!translation) {
      return;
    }
    // Assign translated attributes
    field.type = translation.type;
    field.validations = Object.assign(
      field.validations,
      translation.validations
    );
  });
  return form;
}

// Define translations per type
const translations = {
  int: {
    type: "number",
    validations: {
      integer: true
    }
  }
};
